.leg-1X30u {
	color: var(--text-base);
}

.stepbar-3BHXk {
	order: -1;

	border-top: 0;
}

@media screen and (max-width: 600px) {
	.inner-1D6ql {
		padding: 20px 12px 75px;

		border-radius: 0;

		background: var(--bg-fill);
	}

	.disclaimer_wrapper-3TNwk {
		color: var(--text-light);
		font-size: 13px;
	}

	div.results_withPromocodes-1sTRQ {
		margin-top: 48px;
	}
}
