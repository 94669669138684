.passenger__name-2rKJB,
.passengerInfo__field-1cyhJ {
	color: var(--text-base);
}

button.loayltyNumber__button-f3bYW {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.passenger__name-2rKJB {
		position: relative;

		padding-left: 25px;

		color: var(--text-base);
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
	}

	.passengerInfo__field-1cyhJ {
		font-weight: 400;
	}

	.passenger__name-2rKJB:before {
		position: absolute;
		content: '';

		top: 2px;
		left: 0;

		width: 18px;
		height: 18px;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzEzNTIwXzEyNjA4IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2IDBIMkMwLjkgMCAwIDAuOSAwIDJWMTZDMCAxNy4xIDAuOSAxOCAyIDE4SDE2QzE3LjEgMTggMTggMTcuMSAxOCAxNlYyQzE4IDAuOSAxNy4xIDAgMTYgMFpNNyAxNEwyIDkuMTkyMzFMMy40IDcuODQ2MTVMNyAxMS4zMDc3TDE0LjYgNEwxNiA1LjM0NjE1TDcgMTRaIiBmaWxsPSIjMTkxOTQwIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xMzUyMF8xMjYwOCkiPgo8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IiMwMDZFQUUiLz4KPC9nPgo8L3N2Zz4K);
	}

	.passengerInfo__field-1cyhJ > span:first-child {
		flex: unset;
	}
}
