.container-33pPb {
	justify-content: flex-start;
}

.circle-23o7w {
	top: 55px;

	border: 1px solid var(--line-separator);
}

.route-1ud1s:before {
	top: 56px;

	border-top: 1px solid var(--line-separator);
}

.flightInfo-1XBZj {
	align-items: center;
}

.flightInfo__container-NCAN6 {
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 4px;
}

.flightInfo__container-NCAN6 .flightInfo__icon-2Cdq4 {
	width: 122px;
	height: 33px;
	object-fit: contain;
	margin-right: 0;
	margin-bottom: 4px;
}

.flightInfo__container-NCAN6 .flightInfo__icon-2Cdq4 img {
	width: auto;
}

.flightNumber-1mirc {
	margin-top: 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
}

.date__container-1lqT_ {
	align-items: center !important;
	justify-content: flex-start;
}

.aircraft-2CIPz {
	min-width: auto;

	color: var(--brand-1-0);
	font-weight: 700;
}

.planeInfo-1Q-ok {
	justify-content: center;
}

.inlineFares-3Jus3 .selectFrom-2_QOa {
	margin-bottom: 0;

	color: var(--brand-1-0);
}

button.Button-1wmgL,
button.Button-1wmgL:hover {
	height: 30px;

	color: var(--brand-1-0);

	background: none;
}

.inlineFares-3Jus3 button.Button-1wmgL {
	border-radius: 0;

	color: var(--brand-1-0);

	background: none;
}

.inlineFares-3Jus3 button.Button-1wmgL:hover {
	color: var(--brand-1-0);

	background: none;
}

.totalDuration-vd23R {
	color: var(--brand-3-0);
}

.point-3F4vl {
	align-items: center;
}

div.flightInfo__date-11lpD {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: uppercase;
}

.flight-1-H3h,
.flight-1-H3h.flight_dropdown-1tOhq.flight_selected-1YqtV {
	border-radius: 0;
}

.time-4PaHe {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: var(--text-base);
}

.timeInRoute-3eyJG {
	color: var(--text-light);
	font-weight: 400;
	font-size: 12px;
}

.flightInfo__airline-3aUaw {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-align: center;
}

.fare_family-1oh9w,
.price-3AHwt.price_selected-1EWC6 .price__money-2s5zc {
	color: var(--brand-1-0);
}

span.price_original-2BRnT {
	color: var(--brand-3-2);
}

.price__money-2s5zc.price__money_withPromoCode-3r8Vk {
	color: var(--brand-1-0);
}

.flightPrice__seats-3F533 {
	display: none;
}

.point__date-3EV3L {
	text-transform: capitalize;
}

@media screen and (max-width: 600px) {
	.route-1ud1s {
		margin-top: 20px;
		margin-bottom: 6px;
	}

	.route-1ud1s:before {
		top: 46px;

		width: 80px;
	}

	.circle-23o7w {
		top: 45px;
	}

	.circle-23o7w:not(.circle_right-2wO85) {
		left: 26px;
	}

	.circle_right-2wO85 {
		right: 26px;
	}

	.timeInRoute-3eyJG {
		top: 0;
	}

	.flightNumber-1mirc {
		margin-top: 0;
		margin-bottom: 24px;
	}

	.flightNumber-1mirc.stop-2omSg {
		margin-bottom: 40px;

		font-size: 14px;
	}
}

.airport-eOkMa,
.point_arrival-3-z3g .airport-eOkMa {
	text-align: center;
}

.best-BGAB5 {
	color: var(--brand-3-0);
}
