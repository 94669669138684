svg.icon-2nmtt {
	vertical-align: baseline;
}

.promoCode-204Ef {
	color: var(--brand-1-1);
}

.clearWrapper-14PkW {
	padding-right: 23px;
	padding-left: 12px;
	margin: 0 0 0 10px;

	border-radius: 100px;

	background: #e0f2fa;
}

a.clear-8yEDG {
	color: var(--brand-1-0);
}

a.trigger-18iqA {
	position: relative;

	display: inline-flex;
	align-items: center;
}

.promoCode_notApplied-2h1OQ a.trigger-18iqA {
	padding-left: 20px;

	opacity: 1;
}

.icon_notApplied-17s25 {
	top: auto;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: 16px;
	height: 16px;
	margin-right: 6px;
}

.icon_notApplied-17s25 svg {
	transform: inherit;
}

.contentWrp-14py1 {
	align-items: center;
}
