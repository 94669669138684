.root-2ljWR {
	border-radius: 0%;
}

.PromoMessage__root-V65zf {
	border-radius: 0%;

	color: var(--bg-white);

	background: var(--brand-3-0);
}

.header-3qmoG {
	color: var(--text-base);
}

.service_banner-1sjel {
	display: none;
}

@media screen and (max-width: 600px) {
	.root-2ljWR {
		padding: 12px;

		border-radius: 0;
	}
}
