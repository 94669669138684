.airline__number-1qyjQ,
.aircraft__name-19eIs,
.airline__name-3qSAw {
	color: var(--text-light);
}

.flight-3tagq {
	color: var(--text-base);
}

.info-2vl48 {
	color: var(--brand-1-0);
}

.aircraft__name_active-2UJnb {
	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 13px;
}

.amenities-E4OEK {
	margin-left: 0;
}

@media screen and (max-width: 600px) {
	.aircraft__name-19eIs {
		margin-left: 0;
	}
}

@media screen and (max-width: 320px) {
	.aircraft__name-19eIs {
		margin: 0;
		padding: 0;

		border: 0;
	}
}
