

div.container-31gi0 {
	border-radius: 0;

	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.flight-2xI8- {
	background: none;
}

.passenger-1ABjY {
	margin: 20px 12px;
}

.flight__direction-Qe01c > svg {
	color: var(--text-pale);
}

div.container-31gi0 .notification-Qb-7M {
	border-radius: 0;
}

div.container-31gi0 .notification-Qb-7M .icon-1hrls {
	align-self: start;
}

div.container-31gi0 .notification-Qb-7M .text-1LoIl {
	font-size: 16px;
}

div.buttons-2Jms7 button,
div.buttons-2Jms7 button:hover {
	border-radius: 0;

	font-size: 16px;
}

button.newSearch-EqQn7:hover,
button.newSearch-EqQn7:focus {
	background: var(--brand-1-4);
}

button.continueButton-hJPZo {
	background: var(--bg-accent);
}

button.continueButton-hJPZo:hover,
button.continueButton-hJPZo:focus {
	background: var(--bg-accent-hover);
}
