.item-28508 {
	position: relative;

	height: max-content;
	padding: 6px 10px;

	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.item-28508,
.item-28508.item_active-2s-90,
.item-28508.item_recommend-fwADI.item_active-2s-90 {
	background: var(--white);
}

.item-28508.item_active-2s-90:after,
.item-28508.item_recommend-fwADI.item_active-2s-90:after {
	content: '';

	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;

	height: 3px;

	background: var(--brand-3-0);
}

.money-1wiy- {
	padding-left: 8px;

	font-weight: 500;
}

.payload-nXpTE {
	color: var(--text-base);
	line-height: 1.2;
}

.payload-nXpTE > div:first-of-type {
	font-weight: 500;
}

.payload-nXpTE > div:last-of-type {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
}

.payload-nXpTE svg {
	color: var(--brand-2-2);
}

.money-1wiy-,
.item-28508.item_active-2s-90 .money-1wiy-,
.item-28508.item_recommend-fwADI .money-1wiy-,
.item-28508.item_active-2s-90 svg,
.item-28508.item_recommend-fwADI svg {
	color: var(--brand-2-2);
}

.avatar-1a1sP,
.item_recommend-fwADI .avatar-1a1sP {
	color: var(--text-light);

	background: var(--bg-fill);
}

.item_active-2s-90 .avatar-1a1sP {
	color: var(--white);

	background: var(--brand-3-0);
}

.item-28508.item_recommend-fwADI.item_active-2s-90 .avatar-1a1sP {
	color: var(--white);
}

.passengerName-3Z2jR {
	color: var(--text-base);
}

.select-NTjqe {
	color: var(--brand-3-0);
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.seat-kUAGA {
		color: var(--white);

		background: var(--seat-economy-front);
	}

	button.select-NTjqe {
		border-radius: 0;

		background: var(--brand-1-0);
	}

	button.select_selected-1Cbpl,
	button.select_selected-1Cbpl:hover {
		border: none;
		border-radius: 0;

		color: var(--text-light);

		background: var(--bg-fill);
	}

	.item-28508 {
		padding: 0;

		box-shadow: none;
	}
}
