

.flight-3TF_d.flight_selected-xhuEt {
	border-color: var(--line-separator);
	border-radius: 0;
}

.flight-3TF_d:not(.readonly-pFfL8) .segments-1hXjN {
	border-color: var(--line-separator);
}

.selectedFare-39bc6,
.selectedFare__price-SQM1D {
	color: var(--brand-1-0);
}

button.Button-k4A2- {
	min-height: 34px;

	padding: 0 16px;

	border-radius: 0;

	color: var(--white);

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

button.Button-k4A2-:hover {
	color: var(--white);

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}
