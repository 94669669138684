.name-397Vh {
	margin-top: 51px;

	color: var(--text-light);
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.name-397Vh {
		margin-top: 10px;
		margin-bottom: 8px;
	}

	.flight_direction-2Na1T {
		color: var(--brand-3-0);
	}

	.flight_count-1OQc3 {
		width: 44px;
		height: 44px;

		border: 1px solid var(--brand-1-0);
		border-radius: 0;

		color: var(--brand-1-0);
		line-height: normal;

		background: none;
	}

	.flight_icon-34FtI > svg {
		transform: translate(-50%, -50%);
	}
}
