.wrapper-1C8Qb {
	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.icon-2uOLV {
	border-radius: 0%;

	background: var(--bg-fill);
}

.icon-2uOLV > svg {
	color: var(--brand-1-0);
}

.title-T2IyW {
	color: var(--text-base);
}

.description-2CEC6 {
	color: var(--text-light);
}

.price-J2y0d {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.wrapper-1C8Qb {
		padding: 12px;

		border-radius: 0;
	}

	.icon-2uOLV {
		border-radius: 50%;
	}

	.title-T2IyW {
		font-size: 20px;
	}

	.description-2CEC6 {
		font-size: 14px;
	}

	.wrapper-1C8Qb:not(:first-child) {
		margin-left: 0;
		margin-top: 12px;
	}
}
