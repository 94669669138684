.wrapper-L5abl {
	border-radius: 0;

	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.selected-JS_F_ {
	background: var(--brand-3--2);
	transform: translate(-30%, -47%);
}

.row-1EPdX div[class='slick-list'] {
	overflow: unset;
}

.category_title-17bf_ {
	color: var(--brand-1-0);
}

.category_description-2q7FZ {
	color: var(--text-mid);
}

.wrapper-L5abl.active-2MwEM {
	margin-right: 0;

	background-color: var(--brand-1-0);
}

.clear-1_ooM {
	color: var(--brand-1-0);
}

span.checkbox_root-2o_p3.checkbox_rootChecked-359sN {
	color: var(--brand-1-0);
}

.baggage_wrapper-3y7G4 {
	background: var(--bg-fill);
}

.modalHeader-2T6_T {
	color: var(--text-base);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.root-2w8EA {
		width: auto;
	}

	.equipment-2uxxx {
		max-width: 45%;
	}
}

@media screen and (max-width: 600px) {
	button.selector-3vtx_ {
		border-radius: 0;

		color: var(--brand-1-0);

		background: var(--brand-1-5);
	}

	button.selector-3vtx_.selector_selected-WEJ56 {
		background: var(--brand-1-0);
	}

	.selector__footer-1NxP9 {
		color: var(--brand-1-0);
	}

	.passenger_name-2P0B5 {
		font-size: 16px;
	}

	.price_wrapper-3YYg7 {
		font-size: 20px;
	}

	.selector__price-3M3mQ,
	.passenger_name-2P0B5,
	.price_wrapper-3YYg7,
	.price_label-2YLmw,
	.segment__name-3MK8d,
	.segment__price-22mWt {
		color: var(--text-base);
	}

	.passenger_number-1MdSP,
	.label-2nIQh {
		color: var(--text-mid);
	}

	.baggage_plus-31RQK,
	.baggage_minus-3t2EK {
		max-width: 26px;
		min-width: 26px;
		max-height: 26px;
		min-height: 26px;
	}

	.container-1jp-q.BaggageExcess-1kIfj .mobileBaggageSelectorDescription-3sQUv {
		display: block;
		margin-left: 20px;
	}
}
