.city-1GLh- {
	color: var(--text-base);
}

.terminal-H18WS {
	color: var(--brand-1-0);
}

.date-1HLpE {
	color: var(--text-light);
}

.date_icon-1w4S3 {
	color: #dadada;
}

.time-1LHzV {
	color: var(--text-base);
}

.isMobile-5vr21 .time_iata-1E9Go {
	color: var(--text-light);
}

.weather-1BthM {
	display: none;
}

.isMobile-5vr21 .weather-1BthM {
	display: none;
}

.airport-1l7u6 {
	padding-right: 10px;
}

@media screen and (max-width: 600px) {
	.date_icon-1w4S3 {
		color: var(--text-pale);
	}

	.airline__name-1vLdd {
		color: var(--text-light);
	}
}
