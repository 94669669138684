div.day_withPrice--1fES {
	align-items: center;

	margin: 2px 1px;
	padding: 0;

	color: var(--text-base);
	font-size: 16px;
	line-height: normal;
}

div.day_withPrice--1fES:hover {
	position: relative;

	border: 1px solid transparent !important;
}

div.day_withPrice--1fES:hover:after {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border: 1px solid var(--brand-1-0);
}

div.day_withPrice--1fES > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 4px 2px 2px;

	font-weight: 400;
	line-height: 1.3;
}

div.week-1UOGL {
	margin-bottom: 2px;
}

div.day_withPrice--1fES.day_selected-rOGxy {
	padding-bottom: 16px;
}

div.day_withPrice--1fES.day_selected-rOGxy > div:before {
	position: absolute;
	bottom: 6px;
	left: 50%;
	z-index: 1;

	width: 10px;
	height: 10px;

	transform: translateX(-50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMC41TDQuMTE4NzUgMS4zODEyNUw3LjYwNjI1IDQuODc1SDBWNi4xMjVINy42MDYyNUw0LjExODc1IDkuNjE4NzVMNSAxMC41TDEwIDUuNUw1IDAuNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
	content: '';
}

div.day_withPrice--1fES.day_selected-rOGxy.day_endPeriod-30Yto > div:before {
	transform: translateX(-50%) rotate(180deg);
}

.dialog__activeDate-6PYvo {
	font-family: 'Roboto', sans-serif;
}

div.day_withPrice--1fES.day_selected-rOGxy.day_startPeriod-1CGAI,
div.day_withPrice--1fES.day_selected-rOGxy.day_endPeriod-30Yto {
	padding-bottom: 16px;

	border-radius: 0;

	background: var(--brand-1-0);
}

div.day_withPrice--1fES.day_selected-rOGxy,
div.day_withPrice--1fES.day_startPeriod-1CGAI.day_withPrice--1fES.day_endPeriod-30Yto {
	padding-top: 0;

	border-radius: 0;

	background: var(--brand-1-0);
}

div.day_withPrice--1fES.day_startPeriod-1CGAI.day_withPrice--1fES.day_endPeriod-30Yto {
	padding-bottom: 0;
}

div.day_withPrice--1fES.day_startPeriod-1CGAI.day_withPrice--1fES.day_endPeriod-30Yto > div:before {
	display: none;
}

div.day_withPrice--1fES.day_inPeriod-1xc4H {
	color: var(--text-base);
}

div.monthHeader-3KHdn,
div.yearHeader-2M1sD {
	color: var(--brand-1-1);
	font-size: 18px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}

div.weeklyHeader-18ih5 span {
	font-size: 16px;
	color: var(--text-pale);
	font-weight: 500;
}

div.day_today-zpmbO div.day__inside-2q1Am {
	border: 1px solid var(--line-dot-line);
}

div.day_hasPrice-1qQ6R {
	background: var(--brand-3-5);
}

span.day__price-299pT {
	color: var(--brand-3-0);
	font-size: 9.5px;
	font-weight: 400;
	line-height: 1.3;
}

div.day_endHover_withPrice-2uimH > div,
div.day_withPrice--1fES.day_inPeriod-1xc4H > div,
div.day_withPrice--1fES.day_selected-rOGxy > div {
	background: none;
}

div.day_inPeriod-1xc4H.day_highlighted-2lApe > div {
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	div.day_withPrice--1fES > div {
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}

	div.footer__buttons-1eWOf {
		height: 65px;
		padding: 12px;

		background: var(--bg-white);
	}

	div.footer__done-1oLEc,
	div.footer__clear-vGOP0 {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 162px;
		min-height: 41px;

		font-weight: 700;
		font-family: 'Roboto', sans-serif;
	}

	div.footer__done-1oLEc {
		margin-right: 0;

		background: var(--brand-3-0);
	}

	div.footer__clear-vGOP0 {
		margin-left: 0;

		color: var(--text-light);

		opacity: 1;
		background: var(--bg-fill);
	}

	div.day__inside-2q1Am {
		width: 36px;
		height: 36px;
	}
}
