.header-2x9wk,
.step-1XJIC {
	color: var(--text-base);
}

.controls-2ywwp button {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

.controls__left-34WuX {
	color: var(--text-link);
}

@media screen and (max-width: 600px) {
	.wrapper-3-H4- {
		padding: 40px 20px 20px;
	}

	.header-2x9wk {
		width: 80%;
	}
}
