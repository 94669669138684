button.passenger-yQM4Y {
	background: var(--bg-fill);
}

button.passenger-yQM4Y.active-3F9Kx {
	background: var(--brand-1-4);
}

.passengers-2rrSi .passenger-yQM4Y:not(:last-of-type) {
	border-radius: 0%;
}

span.checkbox-qwG3t.active-3F9Kx {
	color: var(--brand-1-0);
}

.label-2Xy5N.active-3F9Kx {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	button.passenger-yQM4Y > span {
		opacity: 0 !important;
	}

	.passengers-2rrSi .passenger-yQM4Y:not(:last-of-type) {
		margin-bottom: 0;

		border-bottom: 1px solid var(--line-separator);
	}

	button.passenger-yQM4Y {
		padding: 18px 0;
	}

	button.passenger-yQM4Y,
	button.passenger-yQM4Y.active-3F9Kx {
		background: none;
	}
}
