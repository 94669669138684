

.flight_count-2CVSR {
	width: 44px;
	height: 44px;

	border: 1px solid var(--brand-1-0);
	border-radius: 0;

	color: var(--brand-1-0);

	background-color: unset;
}

.flight_direction-1RcLV {
	color: var(--brand-3-0);
}

.card-3UCvw .baggage__icon-3NDYc {
	position: relative;
}

.card-3UCvw .baggage__icon-3NDYc:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 70%;
	height: 70%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCA4QzE0LjU1MjMgOCAxNSA4LjQ0NzcyIDE1IDlWMTFIMTRWMTBDMTQgOS40NDc3MiAxMy41NTIzIDkgMTMgOUgxMUMxMC40NDc3IDkgMTAgOS40NDc3MiAxMCAxMFYxMUg5VjlDOSA4LjQ0NzcyIDkuNDQ3NzIgOCAxMCA4SDE0Wk04IDExVjlDOCA3Ljg5NTQzIDguODk1NDMgNyAxMCA3SDE0QzE1LjEwNDYgNyAxNiA3Ljg5NTQzIDE2IDlWMTFIMThWMThINlYxMUg4Wk0xNCAxM0gxM0gxMUgxMEg3VjE0SDE1VjE1SDE2VjE0SDE3VjEzSDE0Wk0xMyAxMUgxMVYxMEgxM1YxMVpNMiAxMkMyIDExLjQ0NzcgMi40NDc3MiAxMSAzIDExSDVWMThIM0MyLjQ0NzcyIDE4IDIgMTcuNTUyMyAyIDE3VjEyWk0xOSAxOFYxMUgyMUMyMS41NTIzIDExIDIyIDExLjQ0NzcgMjIgMTJWMTdDMjIgMTcuNTUyMyAyMS41NTIzIDE4IDIxIDE4SDE5WiIgZmlsbD0iIzAwNmVhZSIvPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
	transform: translate(-50%, -50%);
}

.card-3UCvw .baggage__icon-3NDYc svg {
	display: none;
}
