.ticketExample-26HDU {
	height: 190px;

	background: url('https://cdn.websky.aero/content/frontend/images/Y7/checkin-itinerary.jpg') no-repeat center /
		contain;
}

.ticketExample-26HDU > div {
	display: none;
}

@media screen and (max-width: 600px) {
	.ticketExample-26HDU {
		height: 110px;
	}
}
