

.header__cell-f5GwO:not(:last-of-type) {
	border-right: 1px dashed var(--line-separator);
}

.header__cell-f5GwO:nth-child(3) {
	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

.header__icon-3ZuiE {
	color: var(--text-pale);
}

.title-2ixm- {
	color: var(--text-base);
}

.travelTime-1JY73 {
	color: var(--text-light);
}

.fareGroup__logo-x8SPm {
	display: none;
}
