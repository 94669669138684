.chip-2Ea0k {
	border-radius: 0;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

@media screen and (max-width: 600px) {
	.chip-2Ea0k,
	.chip-2Ea0k:last-child,
	.chip-2Ea0k:first-child {
		border-radius: 0;

		color: #ffffff;

		background: var(--brand-1-0);
	}

	.chip-2Ea0k:last-child {
		margin-bottom: 12px;
	}

	.close-3Gr7V {
		background: rgba(255, 255, 255, 0.2);
	}
}
