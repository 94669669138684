

.header-XqiCM {
	color: var(--bg-white);
	font-weight: 500;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;

	background: var(--brand-1-0);
}

.stepIndex__label-9jrjw {
	margin-left: 6px;
}

.stepbar-3n9ft {
	background: var(--brand-1-0);
}

.stepIndex-1x-ch {
	margin-left: 6px;
}
