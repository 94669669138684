

.dayWrapper-3ITLw {
	padding: 2px 0 0;

	border-right: 1px solid var(--line-separator);
}

.dayWrapper-3ITLw:last-child {
	border-right: none;
}

.day-2pr2n {
	justify-content: center;
}

.date-AvHNh {
	margin-bottom: 4px;
	padding: 0;

	color: var(--text-light);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

.date__dow-1XHQ_ {
	margin-top: 0;
	margin-bottom: 4px;
	padding: 0;

	line-height: inherit;
}

.date__info-37Tiy {
	position: relative;
	top: 0;

	color: var(--text-light);
	font-weight: 400;
	line-height: 1.3;
}

.date__info-37Tiy > span {
	background: var(--white);
}

.date__info-37Tiy:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;

	width: 50%;

	border-bottom: 1px dashed var(--line-separator);

	transform: translate(-50%, -50%);
	content: '';
}

.price__wrapper-5ilVX {
	flex-grow: inherit;
}

.price-2ilMP {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2;
}

.wrapper__selected-2dEB1 .day-2pr2n {
	border-bottom: none;

	background: var(--brand-1-0);
}

.wrapper__selected-2dEB1 .date-AvHNh {
	color: var(--white);
	font-size: 16px;
}

.wrapper__selected-2dEB1 .date__info-37Tiy > span {
	color: var(--white);

	background: var(--brand-1-0);
}

.wrapper__selected-2dEB1 .date__info-37Tiy:before {
	border-color: rgba(255, 255, 255, 0.4);
}

.wrapper__selected-2dEB1 .price-2ilMP {
	color: var(--white);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.arrow_next-2dX4j:after,
.arrow_prev-Nqiw_:after {
	margin-top: 2px;
}

.day-2pr2n.day_best-22WmQ .price-2ilMP {
	color: var(--brand-3-0);
}

.wrapper__selected-2dEB1 .day-2pr2n.day_best-22WmQ .price-2ilMP {
	color: var(--white);
}
