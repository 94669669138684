.header-2hU7T {
	font-size: 28px;
}

@media screen and (max-width: 600px) {
	.insurances-2pGRF {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		grid-gap: 20px;
		gap: 20px;
	}

	div.insurances-2pGRF > div,
	div.insurances-2pGRF > div:first-child,
	div.insurances-2pGRF > div:last-child {
		margin: 0;
	}
}
