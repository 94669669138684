button.seat-14BTF {
	min-width: 44px;

	border-radius: 0;
}

button.seat-14BTF.seat_recommend-1fZkc {
	background: var(--brand-3-0);
}

button.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx {
	background: var(--white);
}

button.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx:before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;

	border: 3px solid var(--brand-3-0);
	content: '';
}

button.seat_business-YjYPe:not(.seat_recommendCurrent-3Adpx):before {
	content: '';

	position: absolute;
	top: 3px;
	left: 50%;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
	transform: translateX(-50%);

	pointer-events: none;
}

button.seat_business-YjYPe.seat_occupied-1qs_Z:before,
button.seat_business-YjYPe.seat_unavailable-1VV0j:before {
	display: none;
}

button.seat-14BTF .number-1eNOm {
	color: var(--white);
}

.number_name-3bBJZ {
	text-transform: uppercase;
}

button.seat-14BTF.seat_recommend-1fZkc .number-1eNOm {
	color: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	button.seat-14BTF .number-1eNOm {
		display: block;

		color: #ffffff;
		font-size: 14px;
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}
}
