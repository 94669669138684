button.button-13Qki,
button.button-13Qki:hover {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

@media screen and (max-width: 600px) {
	button.button-13Qki.back-1FFjX {
		width: max-content;
		min-width: auto;
		height: 40px;

		padding: 0;

		color: var(--text-light);
		font-weight: 400;
		font-size: 16px;

		background: transparent;
	}

	button.button-13Qki.back-1FFjX span {
		display: none;
	}

	button.button-13Qki.back-1FFjX:before {
		width: 40px;
		height: 40px;

		margin-right: 10px;

		border-radius: 50%;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDcuNUgzLjgzTDkuNDIgMS45MUw4IDAuNUwwIDguNUw4IDE2LjVMOS40MSAxNS4wOUwzLjgzIDkuNUgxNlY3LjVaIiBmaWxsPSIjQTNBM0IzIi8+Cjwvc3ZnPgo=) no-repeat center / contain var(--bg-fill);
		background-size: 16px;
		content: '';
	}
}
