.header-2BOGy {
	color: var(--text-base);
}

.actions-6zWFG a {
	color: var(--text-link);
}

.buttons__wrapper-1zT3d button {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

@media screen and (max-width: 600px) {
	.loyaltyConnect-1ajYU {
		padding: 40px 20px 20px;
	}

	.header-2BOGy {
		width: 80%;
	}

	.group-_kkfA {
		flex-direction: column;
		align-items: inherit;
	}

	.group-_kkfA .group__field-1zb9p {
		padding-right: 0;
		width: 100%;
	}

	button.button-2khFE {
		margin: 0;
	}

	.buttons__wrapper-1zT3d {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
}
