.paper-3kzFF {
	max-width: 1100px;
}

.paper-3kzFF > form {
	flex-direction: row;
}

@media screen and (max-width: 600px) {
	.buttons-CDo58 {
		margin: 20px 20px 0 20px;
	}

	button.button-2dhWw {
		border-radius: 0;

		color: var(--white);
		font-weight: 700;
		font-size: 16px;

		background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
	}

	button.button-2dhWw:disabled {
		color: var(--text-pale);

		background: var(--bg-elements);
	}

	button.button-2dhWw:hover {
		background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
	}

	div.paper-3kzFF {
		margin: 10px 20px;
		padding: 0 20px;
	}
}
