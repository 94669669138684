

.wrapper-39j16 {
	color: var(--brand-3-0);
}

.title-3NNXz {
	position: relative;

	padding-left: 40px;
}

.title-3NNXz:before {
	position: absolute;
	top: 3px;
	left: 0;

	width: 20px;
	height: 20px;
	content: url(data:image/svg+xml;base64,PHN2ZwoJCWNsYXNzTmFtZT0iaWNvbl9pbmNsdWRlZCIKCQl3aWR0aD0iMjQiCgkJaGVpZ2h0PSIyNCIKCQl2aWV3Qm94PSIwIDAgMjQgMjQiCgkJZmlsbD0ibm9uZSIKCQl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCgk+CgkJPHBhdGgKCQkJY2xhc3NOYW1lPSJpY29uX2luY2x1ZGVkX19jaGVjayIKCQkJZmlsbFJ1bGU9ImV2ZW5vZGQiCgkJCWNsaXBSdWxlPSJldmVub2RkIgoJCQlkPSJNMTIgMjJDMTcuNTIyOCAyMiAyMiAxNy41MjI4IDIyIDEyQzIyIDYuNDc3MTUgMTcuNTIyOCAyIDEyIDJDNi40NzcxNSAyIDIgNi40NzcxNSAyIDEyQzIgMTcuNTIyOCA2LjQ3NzE1IDIyIDEyIDIyWk02LjExMzY0IDExLjc3NjFMOS40NTQ1NSAxNC45MTA0TDE3Ljg4NjQgN0wxOSA4LjA0NDc4TDkuNDU0NTUgMTdMNSAxMi44MjA5TDYuMTEzNjQgMTEuNzc2MVoiCgkJCWZpbGw9IiM4YTE3NzYiCgkJLz4KCTwvc3ZnPg==);
}

.title-3NNXz span {
	display: none;
}
