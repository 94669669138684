

.flight-1na8M {
	border: none;
}

.aircraft__name-2iqW0,
.selectedFare-LG0X4 {
	color: var(--brand-1-0);
}

.price__text-YmGiS,
.money-24xaA {
	font: var(--normal-desktop-bold);
	letter-spacing: normal;
}

.controls__wrapper-23j8S {
	align-items: center;
}
