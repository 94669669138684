.contacts-2mEsT {
	order: 2;

	box-shadow: none;
}

.contacts-2mEsT:hover {
	box-shadow: none;
}

.description__header-38VY5:before {
	border-radius: 0;

	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;

	background-color: var(--brand-1-0);
}

.description__text-3YgSG svg {
	color: var(--text-pale);
}

.footer_login-2w-IG {
	border-top: 1px solid var(--line-separator);

	background: var(--white);
}

.footer__text-2HztY {
	color: var(--text-base);
}

.footer__inner-3rh-7 button {
	height: 40px;
	min-height: 40px;

	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

@media screen and (max-width: 600px) {
	.description__text-3YgSG {
		padding-left: 32px;

		font: var(--small-desktop);
	}
}
