.header-29w05 {
	padding: 74px 40px 30px;

	border-radius: 0;

	background: var(--brand-3-0);
}

.header-29w05:after {
	right: 20px;

	width: 100px;
	max-width: 100px;
	height: 60%;

	transform: translateY(-30%);
}

button.button-2kM4X {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.header-29w05 {
		padding: 60px 30% 20px 20px;

		font-size: 24px;
	}
}
