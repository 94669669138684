.card__description-1DvT0 {
	color: var(--text-light);
}

.content__description-1GzDl button {
	height: 52px;
	min-height: 52px;

	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

.miles__link-4hr8S {
	color: var(--text-link);
}

.miles__link-4hr8S:after {
	border-right: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.buttons-23g8K {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
