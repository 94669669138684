button.scroll_up-3nM5O {
	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

.total_time-vOlCs {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.segments-3egHq {
		padding: 20px;
	}

	.buttons-2piNA {
		grid-gap: 10px;
		gap: 10px;
	}

	.buttons-2piNA button.fareGroup__button-382sp {
		max-width: unset;
		margin: 0;
		height: 45px;
		padding: 12px 16px;
	}
}
