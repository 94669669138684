div.field_birthdate-1RhIh {
	width: 50%;
}

@media (max-width: 1024px) {
	.loyalty_switch-1FVde {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

@media screen and (max-width: 600px) {
	.container-2YRaq {
		grid-template-columns: inherit;
	}
}
