

.wrapper-2T0Kw {
	border-radius: 0%;

	background: var(--bg-white);
}

.flightInfo__iatas-21UGw:not(.planeIcon-38NjI) {
	color: var(--text-base);
	font-family: 'Roboto', sans-serif;
	font-size: 44px;
}

.planeIcon-38NjI {
	color: var(--text-pale);
}

.planeIcon-38NjI:after,
.planeIcon-38NjI:before {
	display: none;
}

.planeIcon-38NjI > svg {
	width: 36px;
	height: 36px;
}

.flightInfo__cities-34ibi {
	color: var(--text-light);
	font-size: 13px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	text-decoration: none;
}

.flightInfo__dates-3OCa3 {
	color: var(--text-base);
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 18px;
}

.flightInfo__time-2og6u {
	color: var(--text-base);
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 18px;
}

.flightInfo__weather-1b2E5 {
	display: none;
}

.fareGroup-CpWyH {
	display: none;
}

.arrivalInfo__item-1TdWL:not(.arrivalInfo__title-2tQgz) {
	color: var(--text-base);
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 18px;
}

.arrivalInfo__title-2tQgz {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: var(--text-light);
}
