button.reject-2ZZi6:not(:disabled) {
	justify-content: flex-start;
	padding: 14px 20px;

	color: var(--error-text);
	text-align: start;
}

@media screen and (max-width: 600px) {
	div.info-2uycp {
		padding: 16px;

		border-radius: 8px 8px 0 0;
	}

	div.prices-1HRhh {
		margin-bottom: 10px;
		padding: 10px 0;
	}

	div.prices-1HRhh,
	div.info-2uycp {
		margin: 0 20px;

		background: var(--white);
	}

	div.ticket-1TYDK,
	div.header-Yfcmz {
		background: none;
	}

	.title-2YECc {
		font-weight: 700;
		color: var(--text-base);
	}

	.time-1ejj2 {
		color: var(--text-light);
	}

	div.status-2aT4Q {
		border-radius: 0;
	}

	div.status-2aT4Q.waiting-37uyH,
	div.status-2aT4Q.rejected-1jhlO {
		background: var(--error-icon);
	}

	div.status-2aT4Q.processing-3SK5x {
		background: var(--additional-checkin);
	}

	.buttons-1MqV2 > div button:last-of-type {
		margin-top: 8px;
		margin-left: 20px;
	}

	button.button-SjPVs {
		margin: 0 20px;
		width: auto;
	}

	button.button-SjPVs:first-of-type {
		margin-top: 8px;
	}
}
