

.recommend-33HNg {
	justify-content: inherit;
	padding: 12px;

	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--brand-1-5);
}

.recommend__icon-UqMOq {
	color: var(--text-link);
}

.recommend__label-2gkdC {
	color: var(--text-mid);
	line-height: 1.2;
}

.recommend__label-2gkdC svg {
	color: var(--brand-3-0);
}

.together-2UmfQ {
	color: var(--text-base);
}

.header_fixed-2CKkT {
	position: fixed;
}

.recommend__price-2dv5K {
	color: var(--text-base);
}

button.passengers__button-B-kKb,
button.passengers__button-B-kKb:hover {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.passengers__button-B-kKb.passengers__button_clear-2gRKh {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.passengers__button-B-kKb.passengers__button_clear-2gRKh:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

.recommend__wrapper-1t439 {
	border-radius: 0;
}
