.wrapper-3YhRI,
.details-3i5Xk,
button.gateway__selector-cDwsK {
	border-radius: 0;
}

.content-1miOD {
	justify-content: start;
	padding: 0 30px;
}

.oneMethod-2G3en .content__title-2fcoK {
	font-size: 22px;
	color: var(--text-base);
}

.gateways-2-3Ez {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

button.gateway__selector-cDwsK[data-payment-type='Fps'] {
	order: -3;
}

button.gateway__selector-cDwsK[data-payment-type='Card'] {
	order: -2;
}

button.gateway__selector-cDwsK[data-payment-type='Sbol'] {
	order: -1;
}

button.gateway__selector-cDwsK,
button.gateway__selector-cDwsK:not(:last-child) {
	margin: 0;
}

.gateway__header__arrow-34how {
	color: var(--text-light);
}

.gateway__arrow-At7Bi {
	color: var(--text-pale);
}

.sbp-1Bwga,
.sberpay-3QJZC,
.googlepay-3wujM,
.applepay-4FBWt {
	display: none;
}

.unionpay-F1Zy8 {
	display: inline;
	display: initial;
}

.cards_header-35DWX {
	display: none;
}

@media screen and (max-width: 600px) {
	.details-3i5Xk {
		order: 2;
		margin: 12px 12px 20px;
		padding: 12px 12px 0;

		background: var(--bg-fill);
	}

	.wrapper-3YhRI {
		padding: 16px 0 0 0;

		background: var(--white);
	}

	.content-1miOD {
		padding: 0 12px;
	}

	.oneMethod-2G3en .content__title-2fcoK {
		margin-bottom: 16px;

		color: var(--text-mid);
		font-size: 20px;
		font-weight: 700;
	}

	button.details__button-29rg4 {
		font-weight: 400;
		color: var(--text-link);
	}

	.details__total-xR_wP {
		font-weight: 400;
		font-size: 14px;
	}

	.summary__header-3VOBi {
		margin-bottom: 7px;
	}

	.iframe-bvb1U {
		width: 100%;
	}
}
