

.mobileStep-Cgxwr {
	border-radius: 0;

	background: var(--brand-1-0);
}

.mobileStep-Cgxwr,
.content-1tIVg > div:not(.paper-2AXih) {
	margin: 0 20px;
}
