.confirmed-sUJIr .icon-24mFF {
	color: var(--success-icon);
}

.confirmed-sUJIr .payload-I5N_R {
	color: var(--success-text);
}

.root-37YN7.type_review-lfZcs:not(.confirmed-sUJIr) .payload-I5N_R:before {
	width: 20px;
	height: 20px;

	margin-right: 15px;

	background: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgIGNsYXNzPSJub3RJbmNsdWRlZCIKICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgaGVpZ2h0PSIyNCIKICAgICAgICB2aWV3Qm94PSIwIDAgMjQgMjQiCiAgICAgICAgZmlsbD0ibm9uZSIKICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCj4KICAgIDxwYXRoCiAgICAgICAgICAgIGNsYXNzPSJub3RJbmNsdWRlZF9faW5uZXIiCiAgICAgICAgICAgIGZpbGwtcnVsZT0iZXZlbm9kZCIKICAgICAgICAgICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgICAgICAgICBkPSJNMjAgMTJDMjAgMTYuNDE4MyAxNi40MTgzIDIwIDEyIDIwQzEwLjI1NzUgMjAgOC42NDUxNSAxOS40NDI5IDcuMzMxMjkgMTguNDk3MUwxOC40OTcxIDcuMzMxMjlDMTkuNDQyOSA4LjY0NTE1IDIwIDEwLjI1NzUgMjAgMTJaTTUuODY1MTUgMTcuMTM0OEwxNy4xMzQ4IDUuODY1MTVDMTUuNzQ1NCA0LjcwMDk0IDEzLjk1NDUgNCAxMiA0QzcuNTgxNzIgNCA0IDcuNTgxNzIgNCAxMkM0IDEzLjk1NDUgNC43MDA5NCAxNS43NDU0IDUuODY1MTUgMTcuMTM0OFpNMjIgMTJDMjIgMTcuNTIyOCAxNy41MjI4IDIyIDEyIDIyQzYuNDc3MTUgMjIgMiAxNy41MjI4IDIgMTJDMiA2LjQ3NzE1IDYuNDc3MTUgMiAxMiAyQzE3LjUyMjggMiAyMiA2LjQ3NzE1IDIyIDEyWiIKICAgICAgICAgICAgZmlsbD0iI2EzYTNiMyIKICAgIC8+Cjwvc3ZnPgo=) no-repeat center / contain;
	content: '';
}

@media screen and (max-width: 600px) {
	.buttons-1oKyK {
		margin-top: 0;
	}
}
