

.wrapper-23hhl {
	color: var(--text-base);

	background: var(--white);
}

.header-3Wfly {
	border-color: var(--line-dot-line);
}

.cities-J6-gj {
	order: 2;
}

.planeIcon-1BarA {
	color: var(--text-pale);

	transform: rotate(180deg) translateY(-5px) scale(1.2);
}

.airline__name-2B5i9 {
	font-weight: 500;
}

.flightInfo__value-3a_Fd,
.city__info-10bg- {
	font-family: 'Roboto', sans-serif;
}

.flightInfo__item-1MPZx {
	font-size: 42px;
}

.flightInfo__item-1MPZx:last-child {
	order: -1;
	margin-right: 40px;
	margin-left: 0;
}

.flightInfo__item-1MPZx:last-of-type .flightInfo__value-3a_Fd,
.footer__item-2ChQ9:last-child {
	font-weight: 700;
}

.city__airport-TC7tt,
.flightInfo__title-3wG9M {
	text-transform: uppercase;
	color: var(--text-light);
}

.airline__logo-1et1I {
	width: 110px;
}

.notAvailable-2FMKG {
	opacity: 0.5;
}
