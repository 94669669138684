@media screen and (max-width: 600px) {
	.input-e7SiN,
	.hint-3kM_K {
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}

	.group__label-lpdqL {
		font-weight: 700;
	}

	.label-4d_kJ {
		color: var(--text-base);
		font-family: 'Roboto', sans-serif;
	}

	.code-_pJRC {
		color: var(--text-pale);
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
	}

	.closer-2pOd4 {
		border-radius: 50%;

		background: var(--text-light);
	}

	.closer-2pOd4 > svg > path {
		fill: var(--bg-white);
	}

	.closer-2pOd4 > svg {
		padding: 3px;
	}
}
