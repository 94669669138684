

div.paper-2NoBk span {
	top: 20px;
}

div.paper-2NoBk span svg {
	margin-bottom: 0;
}

div.paper-2NoBk svg circle {
	fill: var(--brand-3-0);
}

button.button-sYDtf {
	border-radius: 0;
}
