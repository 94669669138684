@media screen and (max-width: 600px) {
	.wrapper-1FZ6E {
		padding: 0;
	}

	.header-3Yx82 {
		margin-bottom: 20px;
		padding: 12px 20px;

		background: var(--white);
	}

	.title-3DGZT {
		color: var(--text-base);
		font-size: 18px;
	}

	.close-2o-C8 {
		right: 20px;

		color: var(--text-light);

		background: var(--bg-fill);
	}

	.close-2o-C8 svg {
		transform: scale(0.75);
	}
}

@media (min-width: 1025px) {
	.header-3Yx82 {
		justify-content: center;
	}

	.header_wrapper-2PFDf {
		max-width: 1142px;
		margin: 0 auto;
		padding: 40px 0;
	}
}
