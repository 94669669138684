label.label-qImpY.label_selected-A5M0h {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

.labelDate-W-WT5 {
	background: var(--brand-1-0);
}

span.button-Mt5sU.button_checked-2gH9B {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	label.label-qImpY {
		color: var(--text-base);
	}

	label.label-qImpY.label_selected-A5M0h {
		background: none;
	}

	span.button-Mt5sU {
		color: var(--text-pale);
	}
}
