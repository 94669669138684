

.flight-2ZfZC {
	margin-bottom: 8px;

	border-radius: 0;

	box-shadow: none;
}

.flight-2ZfZC:hover {
	box-shadow: none;
}

.features-2LlhY {
	border-radius: 0;

	background: var(--bg-fill);
}

.feature__icon-aTMpE {
	color: var(--text-light);
}

.feature__text-2KdFE {
	color: var(--text-mid);
}

.features__links-3Etuo > a {
	color: var(--brand-1-0);
}

.info-133dw {
	border-top: 1px solid var(--line-separator);
}
