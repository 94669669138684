.flight-3TmNA {
	border-radius: 0;
}

.routeArrow__route-3N1gU:before {
	top: 50%;
}

.routeArrow__circle-2sfsF {
	top: 0;
}

@media screen and (max-width: 600px) {
	.routeArrow__timeInRoute-1nKH4 {
		margin-bottom: 3px;
	}

	.routeArrow__route-3N1gU:before {
		width: 100%;
	}

	.routeArrow__circle-2sfsF:first-child {
		left: 0;
	}

	.routeArrow__circle-2sfsF:not(:first-child) {
		right: 0;
	}
}
