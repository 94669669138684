button.submit-1flzQ,
button.button-3jA3g {
	border-radius: 0;

	background: var(--bg-accent);
}

button.submit-1flzQ:hover,
button.button-3jA3g:hover {
	border-radius: 0;

	background: var(--bg-accent);
}

span.button-3jA3g.button_checked-BN8j3 {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.header-1ZzPP {
		display: block;
		margin-bottom: 16px;

		color: var(--text-base);
		font-size: 20px;
	}

	div.field-1qRVe {
		margin-bottom: 0;
	}

	button.submit-1flzQ {
		margin-top: 20px;

		border-radius: 0;

		font-weight: 700;
		font-size: 16px;

		background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
	}

	button.submit-1flzQ:hover {
		background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
	}
}
