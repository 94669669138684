

label.element-3rsdy {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 56px;
	padding: 0;

	border-color: var(--line-separator);

	color: var(--line-dot-line);

	background: var(--bg-fill);
}

label.element-3rsdy.element_active-otiGk {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

label.element-3rsdy.element_active-otiGk path {
	fill: var(--brand-1-0);
}

label.element-3rsdy.element_error-2B8Kp {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

label.element-3rsdy.element_error-2B8Kp svg path {
	fill: var(--error-icon);
}

label.element-3rsdy svg {
	position: relative;
	top: auto;
}

label.element-3rsdy:first-of-type,
label.element-3rsdy:last-of-type {
	border-radius: 0;
}

label.element-3rsdy:first-of-type {
	padding-left: 2px;
}

label.element-3rsdy:last-of-type {
	padding-right: 2px;
}

label.element-3rsdy:first-of-type svg {
	right: auto;
}

label.element-3rsdy:last-of-type svg {
	left: auto;
}

label.element-3rsdy input {
	position: absolute;

	display: none;

	pointer-events: none;
}

.error-1Dfy6 {
	width: auto;
}
