div.wrapper-21a_K {
	border-bottom: 1px solid var(--line-separator);
	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

div.wrapper-21a_K:last-child {
	border-radius: 0;
}

div.wrapper-21a_K .header-2AUXN {
	min-height: 76px;
	padding: 0 20px;
}
