.meal-1Po0s,
.image-1QXQ1,
.meal_price-nShwQ,
.meal-1Po0s:after,
button.details_button_small-2nFEl,
.category__item-34yyv {
	border-radius: 0;
}

.details_image-1gGmQ {
	background-size: auto;
	background-color: #ffffff;
}

.meal_selected-3Qex_ {
	outline: 1px solid var(--brand-1-2);
}

.meal_name-3X_99,
.trigger-2kzLC,
.details_description-2OfDY {
	color: var(--text-mid);
}

.details_name-MhAaI,
.details_price-XWkgY {
	color: var(--text-base);
	font-size: 22px;
}

.meal_name-3X_99,
.details_name-MhAaI,
.trigger-2kzLC {
	font-weight: 700;
}

.meal_name-3X_99,
.trigger-2kzLC,
.description__row-2oLX0,
.passenger-21s66 {
	font-family: 'Roboto', sans-serif;
}

.meal_price-nShwQ {
	color: var(--white);
	font-family: 'Roboto', sans-serif;

	background: var(--brand-1-0);
}

.meal-1Po0s {
	padding-bottom: 20px;
}

.meal-1Po0s:after {
	left: -1px;
	right: -1px;
	top: -1px;
	bottom: -1px;
}

.meal-1Po0s:hover:after {
	border-color: var(--brand-1-2);
}

.meal-1Po0s:hover .meal_price-nShwQ {
	background: var(--brand-1--1);
}

.addForAll-Scgnt,
.clear-3hwij {
	color: var(--brand-1-0);
	font-weight: 500;
}

.passenger-21s66 {
	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
}

.passenger__index-1AGdz {
	margin-right: 12px;

	color: var(--text-light);

	background: var(--bg-fill);
}

.counter_button-3Ktk9 {
	min-width: 26px;
	max-width: 26px;
	min-height: 26px;
	max-height: 26px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.counter_button-3Ktk9:not(.counter_button_disabled-fvngG):active {
	color: var(--white);

	background: var(--brand-1--1);
}

.counter_button-3Ktk9:not(.counter_button_disabled-fvngG):hover {
	color: var(--white);

	background: var(--brand-1-0);
}

.counter_button-3Ktk9:not(.counter_button_readonly-18IbB).counter_button_disabled-fvngG {
	opacity: 0.6;
}

.counter_button-3Ktk9 svg {
	transform: scale(0.85);
}

.details_content-29CI3 {
	background: var(--bg-fill);
}

button.details_button_empty-S2xsP {
	background: var(--brand-1-0);
}

button.details_button_empty-S2xsP:hover {
	background: var(--brand-1--1);
}

button.details_button_small-2nFEl:not(.details_button_empty-S2xsP) {
	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.details_button_small-2nFEl:not(.details_button_empty-S2xsP):hover {
	background: linear-gradient(45deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.details_button_small-2nFEl:not(.details_button_empty-S2xsP):active {
	background: linear-gradient(345.05deg, var(--brand-3-2) -35.61%, var(--brand-3-0) 71.37%);
}

.count-1UMnd {
	font-weight: 500;

	background: var(--brand-3-0);
}

.total-IBys2 {
	color: var(--text-base);
}

.closeIcon__wrapper-3I2NI {
	color: var(--text-pale);

	background: var(--bg-fill);
}

.closeIcon__wrapper-3I2NI:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

.category__item-34yyv {
	color: var(--brand-1-0);

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.category__item-34yyv.category__item_selected-2_DWX {
	padding: 11px 14px;

	color: var(--white);

	background: var(--brand-1-0);
}

.category__item-34yyv.category__item_selected-2_DWX .category__icon-3gs_u {
	display: none;
}

.segmentTabs_meal-3H1FZ .segmentTabs__right-2q_Q4 {
	margin-right: 10%;
}

@media (min-width: 1024px) {
	.meals-3O1Cd:not(.meals_singleMeal-2kYfn) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.meals_singleMeal-2kYfn {
	grid-template-columns: 1fr;
}

.meal_fullSize-K35Wu {
	padding-bottom: 0;
}

.meal_fullSize-K35Wu .image-1QXQ1 {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.meal-1Po0s {
		height: auto;
		min-height: 376px;
	}

	.category__item-34yyv.category__item_selected-2_DWX {
		color: var(--brand-1--1);

		background: var(--brand-1-5);
	}

	.details_image-1gGmQ {
		background-size: contain;
	}

	.category__item-34yyv {
		width: 100%;
		margin: 0;

		color: var(--text-mid);
		font-weight: 500;

		font-size: 16px;
		font-family: 'Roboto', sans-serif;

		box-shadow: none;

		background: var(--bg-white);
	}

	.wrapper-21l1M {
		flex-direction: column;
	}

	button.details_button_small-2nFEl:not(.details_button_empty-S2xsP) {
		color: var(--bg-white);
		font-weight: 700;
		font-family: 'Roboto', sans-serif;

		background: var(--brand-1-0);
	}

	button.details_button_small-2nFEl:not(.details_button_empty-S2xsP):hover {
		background: var(--brand-1-1);
	}

	button.details_button_small-2nFEl:not(.details_button_empty-S2xsP):active {
		background: var(--brand-1-1);
	}

	.details_name-MhAaI,
	.details_price-XWkgY {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 20px;
	}

	.image-1QXQ1 {
		height: 280px;
	}
}
