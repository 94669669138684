

.root-K4MVs {
	border-radius: 0%;
}

.header-1dEqC svg {
	color: var(--brand-3--2);
}

.header-1dEqC h2 {
	color: var(--text-base);
}
