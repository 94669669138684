.wrapper-1Cf3t {
	margin-bottom: 28px;
	padding-top: 12px;
	padding-bottom: 28px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: var(--white);
}

.title-25-LU {
	display: flex;
	align-items: center;
	height: auto;
	padding-top: 10px;
	padding-bottom: 10px;

	color: var(--brand-3-0);
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;

	background: var(--white);
}

.title-25-LU:before {
	background: var(--brand-3-0);
}

.title-25-LU > svg {
	display: block;
	width: 36px;
	height: 36px;
	margin-right: 16px;

	color: var(--brand-3-0);
}

.selectedContentClassName-22J_n {
	padding: 0;
}

.selectedContentClassName-22J_n .selectedModal__segments-3WRu0 {
	margin-left: 0;
	flex: 0 1 100%;

	border-left: 1px solid var(--line-separator);
}

.selectedContentClassName-22J_n .selectedModal__segmentFlight-5lVoe {
	margin-right: 0;

	border-right: 1px solid var(--line-separator);
}

.selectedContentClassName-22J_n .selectedModal__segmentFlight-5lVoe:last-child {
	border-right: none;
}

.selectedContentClassName-22J_n .selectedModal__time--7x7h,
.selectedContentClassName-22J_n .transfer-2-8Rd {
	margin: 10px 20px 14px;
}

@media screen and (max-width: 600px) {
	.title-25-LU,
	.wrapper-1Cf3t {
		width: calc(100% + 40px);
		margin-left: -20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.title-25-LU {
		margin-left: 0;
		padding-left: 40px;
	}
}
