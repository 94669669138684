.form-19CNe:hover {
	box-shadow: none;
}

.disabilities__switchControlErrorText-d5x59 {
	font-size: 12px;
}

@media screen and (max-width: 600px) {
	.disabilities__container-e0WDG {
		margin-top: 12px;
	}
}
