.description_block__description-3bgAH a.description_block__rules-22u1U {
	color: var(--brand-3-2);
	font-size: 16px;
}

.description_block__title-3tN1k {
	margin-bottom: 5px;
}

.switchBlock__root-2qH73 {
	color: var(--brand-1-0);

	background: var(--white);
}

.switchBlock__priceLabel-3fx2a {
	display: none;
}

.switchBlock__switchLabel-3Nr4w {
	color: var(--gray80);
	font-size: 16px;
}

.switchBlock__switchWrapper-3POdp {
	display: block;
	padding-right: 0;

	text-align: center;
}

.switch_active-2BJWQ:before {
	background: var(--white);
}

.switchBlock__switchControl-2IAUQ:not(.switch_active-2BJWQ):after {
	background: var(--gray90) !important;
	opacity: 0.2 !important;
}

.description_block__confirmedIcon-ARqJU {
	background: var(--brand-3-0);
}

.root_confirmed-2KxAR .description_block__rules-22u1U {
	display: none !important;
}

@media (min-width: 1024px) {
	.switchBlock__priceWrapper-5nx6C {
		justify-content: center;
		padding-right: 0;
	}

	.switchBlock__root-2qH73 {
		border-left: 1px dashed var(--gray20);
	}
}

@media screen and (max-width: 600px) {
	.root-3CPrv {
		border: 1px solid var(--brand-1-3);
	}

	.description_block-2U7WV {
		display: block;

		text-align: center;

		background: var(--brand-1-5);
	}

	.description_block__icon-YyghO svg {
		width: auto;
		height: auto;
		margin-bottom: 3px;

		border-radius: 50%;

		box-shadow: inset 0 0 1px 1px var(--brand-1-2);
		transform: scale(0.8);
	}

	.description_block__icon-YyghO svg path:first-of-type {
		fill: var(--brand-1-0);
	}

	/* .description_block__icon svg circle {
		fill: var(--brand-1-0);
	} */

	.description_block__title-3tN1k {
		margin-bottom: 7px;

		color: var(--brand-1-0);
		font-size: 20px;
	}

	.description_block__description-3bgAH {
		color: var(--text-light);
		font-size: 16px;
	}

	.switch_block-r2b1Q {
		color: var(--brand-1-0);
		font-weight: 500;

		background: var(--white);
	}

	.switch_active-2BJWQ:before {
		background: var(--white);
	}

	.switch_active-2BJWQ:after {
		background: var(--brand-1-0);
	}
}
