label.formLabel-1Regq {
	padding-right: 80%;
}

button.button-3892p {
	margin-top: 8px;

	border: none;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.button-3892p,
button.refuse-3lcKs {
	border-radius: 0;
}

button.button-3892p:hover {
	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

div.comment-26rwk {
	display: none;
}

@media screen and (max-width: 600px) {
	label.agree-dvfV7 {
		margin: 20px;
	}

	label.formLabel-1Regq {
		padding-right: 0;

		color: var(--text-base);
		font-size: 20px;
	}

	div.control-40PIZ {
		margin: 0;
	}

	span.checkbox-3mtaI {
		color: var(--text-pale);
	}

	span.checkbox-3mtaI.active-1MJ5J {
		color: var(--brand-1-0);
	}

	.chip-3FrT1,
	.chip-3FrT1:last-child,
	.chip-3FrT1:first-child {
		margin: 0;
	}
}
