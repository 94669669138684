@media screen and (max-width: 600px) {
	.header-239EC {
		margin-top: 15px;

		color: var(--text-mid);
	}

	.route-3fhWp {
		border-radius: 0;

		font-size: 16px;

		background: var(--brand-1-0);
	}

	.disclaimer__wrapper-rlFZJ {
		color: var(--text-light);
	}
}
