

div.input-1eAjT {
	padding: 8px 12px 8px 0;
}

div.input-1eAjT:not(.input_standard-2-i-d) {
	border: none;
	border-bottom: 1px solid var(--text-light);
	border-radius: 0;
}

p.helperText_account-25XtX {
	position: static;
	position: initial;

	transform: none;
}

p.helperText-3BNBQ {
	margin-left: 0;
}

svg.icon-1LLbk {
	top: calc(50% - 8px);
}
