.wrapper-EHnsM.context-3SXCy {
	justify-content: start;

	border-radius: 0;
}

.wrapper-EHnsM div {
	color: var(--text-mid);
}

.wrapper-EHnsM .icon-2-NBv {
	margin-right: 11px;

	color: var(--text-light);
}

.context-3SXCy .text-2bwQZ {
	font-weight: 400;
	font-size: 16px;
}

.wrapper-EHnsM[data-code='noticefornorilsk'] {
	border: 1px solid #f3e0e9;

	background-color: #f9eff4;
}

@media screen and (max-width: 600px) {
	.wrapper-EHnsM.context-3SXCy {
		margin-bottom: 12px;
	}
}
