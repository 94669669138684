/* stylelint-disable */

.header-1JL7I {
	color: var(--brand-3-0);
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

.icon-3ZsAP {
	background: var(--brand-1-0);
}

button.back-1URHi,
button.back-1URHi:hover {
	border-radius: 0%;

	color: var(--text-light);
}

button.button-2YKO7 {
	border-radius: 0%;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

button.button-2YKO7:hover {
	border-radius: 0%;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

@media screen and (max-width: 600px) {
	.header-1JL7I {
		background: var(--brand-1-0);
		color: var(--white);
		border-radius: 0;
		font-weight: 500;
	}
}

/* stylelint-enable */
