@media screen and (max-width: 600px) {
	div.prices-1v2uH {
		padding: 12px;
	}

	.price__totalTitle-3Fn2o,
	.money-1FfDf {
		font-size: 16px;
		color: var(--text-base);
	}

	.price__row-1GcZq {
		margin-top: 5px;
		margin-bottom: 0;
	}
}
