/* stylelint-disable */

.title-2AfgH {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

.title__text-HGZT4 {
	color: var(--brand-3-0);
}

.title__cities-ffMPP {
	color: var(--brand-1-0);
}

.title__cities-ffMPP span:last-child {
	margin-left: 38px;
	position: relative;
}

.title__cities-ffMPP span:last-child:after {
	position: absolute;
	top: 50%;
	left: -31px;

	display: block;
	width: 22px;
	height: 3px;

	background: var(--brand-1-0);
	content: '';
}

.title-2AfgH svg {
	display: none;
}

.diagram-JzkLq {
	order: 1;
}

.diagram-JzkLq svg g path {
	fill: var(--brand-1-0);
}

.diagram-JzkLq svg g {
	opacity: 1;
}

.legnumber-3xxrO {
	background: var(--brand-3-0);
}

button.calendar-71_AB:hover {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	button.calendar-71_AB {
		border-color: var(--brand-1-1);

		background: var(--brand-1-1);
	}

	div.day_highlight-qEIW5 {
		background: none;
	}

	.day__inside-30VYi,
	.day_selected-NynBt .day__inside-30VYi,
	div.day_highlight-qEIW5 {
		border-radius: 0;
	}
}
/* stylelint-enable */
