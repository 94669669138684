.inner-2Sq1U {
	margin: 0 auto;

	box-shadow: none;
}

.wrapper-BsO6Z {
	background: var(--bg-fill);
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.promoCode__wrp-S6W0J {
	justify-content: center;
}

.form-3ArrZ {
	background-color: var(--bg-fill);
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.route-2x2_I {
	position: relative;

	border: none;

	color: var(--text-base);
	font-weight: 500;
	font-size: 18px;
}

.route-2x2_I:not(:first-child):before {
	background-color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.route-2x2_I:before {
		display: none;
	}

	.route-2x2_I {
		position: unset;

		font-size: 16px;
	}

	.inner-2Sq1U {
		box-shadow: 0 4px 10px rgb(0 0 0 / 7%);
	}

	.promoCode-6i2Ux {
		margin: 0;

		color: var(--white);
	}

	.promoCode__applied-1ZZxD .promoCode__icon-Dl4pJ {
		color: var(--white);
	}
}
