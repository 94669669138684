.seatMap__wrapper-2o2lP {
	border-bottom: none;
}

.seatMap-x1CwD {
	padding: 26px;
}

.row__serviceHeader__wrapper-3wAqn {
	text-transform: none;
	color: var(--white);

	background: var(--seat-economy-base);
}

.wings-3mNpJ:after,
.wings-3mNpJ:before {
	background: var(--bg-elements);
}

.exit__label-kr_mq {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.row__serviceHeader__wrapper-3wAqn {
		font-family: 'Roboto', sans-serif;
	}
}
