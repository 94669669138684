.toolbar-tq2rq {
	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
}

button.button-3yw0L,
button.button-3yw0L:hover {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.button-3yw0L:disabled,
button.button-3yw0L:disabled:hover {
	border-radius: 0;

	background: var(--light-gray);
}

button.backButton-1S0p3 {
	width: max-content;
	min-width: auto;
	height: 40px;

	padding: 0;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;

	background: transparent;
}

button.backButton-1S0p3:hover {
	background: transparent;
}

button.backButton-1S0p3 span {
	display: none;
}

button.backButton-1S0p3:before {
	width: 40px;
	height: 40px;

	margin-right: 10px;

	border-radius: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDcuNUgzLjgzTDkuNDIgMS45MUw4IDAuNUwwIDguNUw4IDE2LjVMOS40MSAxNS4wOUwzLjgzIDkuNUgxNlY3LjVaIiBmaWxsPSIjQTNBM0IzIi8+Cjwvc3ZnPgo=) no-repeat center / contain var(--bg-fill);
	background-size: 16px;

	transition: background 0.15s;
	content: '';
}

button.backButton-1S0p3:hover:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDcuNUgzLjgzTDkuNDIgMS45MUw4IDAuNUwwIDguNUw4IDE2LjVMOS40MSAxNS4wOUwzLjgzIDkuNUgxNlY3LjVaIiBmaWxsPSIjQTNBM0IzIi8+Cjwvc3ZnPgo=) no-repeat center / contain var(--bg-elements);
	background-size: 16px;
}

button.nextButton-39M5O {
	border-radius: 0;

	font-size: 18px;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

.price-1Qdvh {
	color: var(--text-base);
}

.actions-3yTt-:last-child {
	justify-content: flex-end;
}

.backButton__wrapper-339zR {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.toolbar-tq2rq {
		min-height: 65px;
		padding: 12px;
	}

	.inner-1GIZ2 {
		padding: 0;
	}

	.actions-3yTt- {
		min-height: unset;
	}

	button.nextButton-39M5O {
		height: 41px;

		font-size: 16px;
		font-weight: 700;
		line-height: 1;
	}
}
