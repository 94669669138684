.root-12ySP.type_review-3jkRu .orderCode-X0EYR {
	color: var(--text-base);
	font-weight: 700;
}

.security_code-2wKOp {
	display: none;
}

.root-12ySP.type_review-3jkRu .price-X2CRa,
.root-12ySP.type_review-3jkRu .security_code-2wKOp {
	font-weight: 400;
	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.root-12ySP.type_review-3jkRu span.security_code-2wKOp {
		display: none;
	}
}
