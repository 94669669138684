

button.cancel-1s_ms,
button.cancel-1s_ms:hover {
	color: var(--text-light);

	background: var(--bg-fill) !important;
}

button.confirm-249Gt {
	color: var(--white);

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}
