.title-2aEhR {
	font-size: 2.5rem;
}

button.button-1WLpD {
	border-radius: 0;
}

button.button-1WLpD,
button.button-1WLpD:hover {
	background: var(--bg-accent);
}

.location-3YCUy.from-2OXJ5 .input-2vRQT,
.location-3YCUy.to-1kGp5 .input-2vRQT {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.title-2aEhR {
		font-size: 1.5rem;
	}
}
