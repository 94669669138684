.segmentTabsWrapper-3MVjs {
	box-shadow: none;
}

div.segmentTabsInner-3ujwm {
	flex-wrap: wrap;
	height: auto;
	padding-top: 30px;

	border-bottom: 1px solid var(--line-separator);
}

.segmentTabsSegments-3jCjd {
	margin-left: 0;
}

.title-37fbr {
	width: 100%;
	margin-bottom: 20px;

	color: var(--text-base);
}

button.segmentTabsTab-Bor5j {
	padding-right: 0;
	padding-left: 0;

	border: none;
	border-bottom: 3px solid transparent !important;
	border-radius: 0;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;

	background: transparent;
}

button.segmentTabsTab-Bor5j:not(:last-child) {
	margin-right: 20px;
}

button.segmentTabsTab-Bor5j.segmentTabsTab__active-WirLw {
	border: none;

	color: var(--brand-1-0) !important;

	background: transparent !important;
}

button.segmentTabsTab-Bor5j.segmentTabsTab__active-WirLw:before {
	content: '';

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	height: 2px;

	background: var(--brand-1-0);
}

.fareConditionsContent-u4Jzn pre {
	color: var(--text-base);
}

.tabsWrp-oEIYE:only-child {
	min-height: 80px;
}

@media screen and (max-width: 600px) {
	.segmentTabsInner-3ujwm {
		margin-top: 0;
	}

	.segmentTabsSegments-3jCjd {
		overflow: visible;
	}
}
