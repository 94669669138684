.header__name-2Jz64,
.header__name-2Jz64 circle {
	color: var(--brand-3-0);

	fill: var(--brand-3-0);
}

.price-3bUgx {
	display: none;
}

@media screen and (max-width: 600px) {
	.charge-1-ZMW {
		position: relative;

		padding: 12px;

		color: var(--text-mid);
		font-size: 16px;
	}

	.charge-1-ZMW:last-of-type:before {
		position: absolute;
		top: 0;
		right: 12px;
		left: 12px;

		height: 1px;

		background: var(--line-separator);
		content: '';
	}

	.charge-1-ZMW:not(:last-of-type) {
		margin-bottom: 0;
	}

	.charge__money-3QSMU {
		font-weight: 400;
	}

	.total-24RtQ {
		width: 100%;
		justify-content: space-between;
		align-items: baseline;
	}

	.charge__money-3QSMU,
	.total__money-2Pt-l,
	.total-24RtQ {
		font-size: 16px;
	}

	.total__money-2Pt-l,
	.total-24RtQ {
		color: var(--text-base);
		font-weight: 700;
	}

	.header__name-2Jz64:before {
		display: none;
	}

	.header__name-2Jz64 svg {
		display: block;
	}

	.header__name-2Jz64 svg circle {
		fill: var(--brand-3-0);
	}

	.change-3ROle {
		color: var(--brand-1-0);
	}
}
