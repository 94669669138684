.logo-3RgEY svg {
	width: auto;
	height: auto;
}

@media screen and (max-width: 600px) {
	.logo-3RgEY {
		margin: 104px 0 10px;
	}

	.logo-3RgEY svg {
		transform: scale(0.4);
	}
}
