.header__text-1hHfP {
	color: var(--text-mid);
	word-spacing: nowrap;
}

.container-FH5eh > div {
	display: flex;
	flex-direction: column;
}

@media (max-width: 1024px) {
	.closeIcon-2g18l {
		top: 17px;
		right: 20px;
	}
}

@media screen and (max-width: 600px) {
	.header-Jef1w {
		padding: 0;
	}

	.header__text-1hHfP {
		margin-right: 0;
		padding: 12px 20px;

		color: var(--text-base);
		font-size: 18px;

		background: var(--white);
	}

	.closeIcon-2g18l {
		top: 8px;
		right: 20px;

		color: var(--text-light);

		background: var(--bg-fill);
	}

	.closeIcon-2g18l svg {
		transform: scale(0.75);
	}
}
