.welcomeMsg-4MzaN {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 500;
	line-height: 1.3;
}

.descriptionMsg-asAIt {
	margin-top: 24px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 18px;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.welcomeMsg-4MzaN {
		font-size: 31px;
	}
}
