.value-1srMr {
	position: absolute;
	top: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;

	background: var(--bg-fill);
}

.value-1srMr.value_open-2opZf {
	justify-content: flex-start;

	background: none;
}

.label-2F9ER {
	flex: 0;

	color: var(--brand-3-0);
	font-size: 14px;
}

.iata-tITVE {
	display: flex;
	flex: 1;
	align-items: center;

	color: var(--brand-1-1);
	font-weight: 500;

	font-size: 42px;
	line-height: 130%;
}

.placeholder-Lk5Vn {
	display: flex;
	flex: 1;
	align-items: center;

	color: var(--text-light);
	font-size: 13px;
}

.city-1PwD3 {
	max-height: 16px;

	text-align: center;
	color: var(--text-base);
	font-size: 16px;
}
@media screen and (max-width: 600px) {
	.city-1PwD3 {
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
	}

	.iata-tITVE {
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}

	.label-2F9ER {
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
	}
}
