.airplane-3UYWS {
	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.details-2aKmc {
	border-color: var(--line-separator);
}

.airplane__name-3SqFH {
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.airplane__name-3SqFH a {
	text-decoration: none;
}

.airplane__image-1aqi1 {
	display: none;
}

.airplane__info-1xfY2 {
	padding: 12px;
}

.segment-xlWTq {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.airplane__image-1aqi1 {
		display: none;
	}

	.segment-xlWTq {
		font-weight: 500;
		font-size: 18px;
		font-family: 'Roboto', sans-serif;
	}

	.airplane__name-3SqFH a {
		color: var(--text-light);
		font-weight: 400;
	}

	.seatsDetails-FrBji {
		padding-left: 10px;

		border: 1px solid var(--brand-1-2);

		color: var(--brand-1-0);

		background: var(--brand-1-5);
	}
}
