

.footer-2r83w {
	border-color: var(--line-separator);
}

button.button_default-Ki7a9 {
	border: none;
	border-radius: 0;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.button_default-Ki7a9:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}
