

div.tooltip-1frBr {
	margin-bottom: 8px;

	border-radius: 0;

	background: var(--brand-3-0);
}

div.tooltip-1frBr:after {
	border-top-color: var(--brand-3-0);
}

div.tooltip-1frBr.tooltip_withInfo-3ChJ1:after {
	border-top-color: var(--brand-3-1);
}

div.info-z5XAl {
	padding: 10px 16px;

	border-radius: 0;

	background: var(--brand-3-1);
}

.details__seatName-1GwNs {
	color: var(--white);
}

.number-2P3R0 {
	border-right: none;
}
