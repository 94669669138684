.fareHeadingItem-24k26 {
	background: var(--brand-1-1);
}

.fareHeadingItem_isKey-HtPNi {
	background: var(--brand-3-0);
}

.tableRow-2kD-m {
	border-bottom: 1px solid var(--line-separator);
}

.tableRow-2kD-m:nth-child(even) {
	background: var(--bg-fill);
}

.tableRow-2kD-m .title-3Oc4_ {
	align-items: flex-start;
	padding-top: 3px;
	flex: 22%;

	color: var(--text-base);
}

.availabilityIcon-2j0M8 {
	top: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;

	color: var(--brand-1-0);
}

.availabilityIcon-2j0M8 svg {
	width: 100%;
	height: 100%;
}

.fareHeadingItem__serviceClass-1kL77 {
	display: none;
}

.fareHeadingItem__title-17DBf {
	color: var(--gray);
}

@media screen and (max-width: 600px) {
	.fareHeadingItem__title-17DBf {
		color: var(--brand-2-1);
	}
}

.col-2r7SX {
	position: relative;
}

.groupTitle-3F7P4 {
	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 500;
	line-height: 1.2;
}

.groupTitle-3F7P4,
.headingCell-aUsy9 {
	border-bottom: 1px solid var(--line-separator);
}

.headingWrp-txPi8 .image-3Jfcy {
	display: none;
}

.slider-2UE1d {
	flex: 66%;
}

@media screen and (max-width: 600px) {
	.content-1NP4B {
		padding: 56px 0 0;
	}

	.fareHeadingItem-24k26 {
		min-height: 65px;

		font-size: 16px;
		font-weight: 500;
		color: var(--text-mid);

		background: var(--brand-1-4);
	}

	.slider-2UE1d {
		flex: 1;
	}

	.slider-2UE1d > .slider-2UE1d > div > div:first-of-type {
		left: 12px;
	}

	.slider-2UE1d > .slider-2UE1d > div > div:nth-of-type(3) {
		right: 12px;
	}

	.slider-2UE1d > .slider-2UE1d > div > div:first-of-type,
	.slider-2UE1d > .slider-2UE1d > div > div:nth-of-type(3) {
		top: 33px;

		background: var(--white);
		box-shadow: var(--shdw-card);
	}

	.tableRow-2kD-m {
		grid-gap: 5px;
		gap: 5px;
	}

	.baggage-13Sw5 {
		border-top: 1px solid var(--line-separator);
	}

	.tableRow-2kD-m .title-3Oc4_ {
		flex: 0.9;
		min-width: auto;

		font-weight: 400;
		font-size: 13px;
	}

	.cell-2BA9l {
		font-size: 13px;
		color: var(--text-base);
	}

	div.compareFares-1lTyF {
		border-radius: 0;
	}

	.headingWrp-txPi8 .image-3Jfcy {
		display: block;
	}

	.groupTitle-3F7P4 {
		display: none;
	}

	.link-HCHpc {
		justify-content: center;
		margin: 16px 0 0;
	}

	.slider-2UE1d .slick-prev path,
	.slider-2UE1d .slick-next path {
		fill: #8e8eac;
	}
}
