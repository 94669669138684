

.promo__image-UosII {
	background-size: contain;
}

.promo__image-UosII:after {
	display: none;
}

.promo__content-2OrWQ {
	display: none;
}
