

/* stylelint-disable */

.icon-3H3Ew {
	background: var(--brand-1-0);
}

.icon-3H3Ew ~ h6 {
	color: var(--brand-3-0);
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}

.icon-3H3Ew svg[class='choosed'] {
	width: 20px;
	height: 20px;
	padding: 2px;

	border-radius: 50%;

	color: var(--brand-1-0);

	background: var(--bg-fill);
}

/* stylelint-enable */
