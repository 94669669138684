.icon-1rdv8 {
	margin-right: 8px;

	transform: scale(1.32);
}

.wrapper-2huOO {
	background: var(--white);
}

.form-19DYt button.submitButton-3Pu7f {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.addButton-1BZzs {
		justify-content: center;
		padding: 10px 65px;

		color: var(--brand-1-0);
		white-space: nowrap;

		background: var(--brand-1-4);
	}

	.icon-1rdv8 {
		display: none;
	}

	.addButton-1BZzs > span:not(.icon-1rdv8) {
		position: relative;

		padding-right: 23px;
	}

	.addButton-1BZzs > span:not(.icon-1rdv8):after {
		position: absolute;
		top: 1px;
		right: 0;

		display: flex;
		width: 13px;
		height: 13px;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjYyNSA3LjM3NUg3LjM3NVYxMi42MjVINS42MjVWNy4zNzVIMC4zNzVWNS42MjVINS42MjVWMC4zNzVINy4zNzVWNS42MjVIMTIuNjI1VjcuMzc1WiIgZmlsbD0iIzAwNkVBRSIvPgo8L3N2Zz4K);
		content: '';
	}

	.form-19DYt button.submitButton-3Pu7f {
		border-radius: 0%;

		background: var(--brand-3-0);
	}

	.form__title-2BdLV {
		color: var(--text-base);
		font-size: 18px;
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
	}
}
