div.size-2F3ev {
	color: var(--brand-1-0);
}

.root-1hWnt.checked_baggage-1bto3.large-11zKp .size-2F3ev {
	bottom: -36px;

	flex-flow: row nowrap;
	justify-content: center;
	align-items: baseline;
}

.root-1hWnt.carry_on-ejEuO.small-3UzDt .size-2F3ev,
.root-1hWnt.carry_on-ejEuO.large-11zKp .size-2F3ev {
	top: auto;
	bottom: -36px;

	flex-wrap: nowrap;
}

.root-1hWnt.checked_baggage-1bto3.small-3UzDt .size-2F3ev,
.root-1hWnt.checked_baggage-1bto3.middle-2GPC_ .size-2F3ev {
	bottom: -36px;

	flex-flow: column nowrap;
}

.measurement-BwJv_ {
	margin-left: 4px;
}

@media screen and (max-width: 600px) {
	.root-1hWnt {
		max-height: 100px;
	}

	.root-1hWnt.checked_baggage-1bto3.large-11zKp .size-2F3ev {
		flex-direction: column;
		justify-content: center;
		align-items: normal;
	}

	.root-1hWnt.checked_baggage-1bto3.small-3UzDt .size-2F3ev,
	.root-1hWnt.checked_baggage-1bto3.middle-2GPC_ .size-2F3ev,
	.root-1hWnt.checked_baggage-1bto3.large-11zKp .size-2F3ev {
		bottom: 20px;
	}

	.root-1hWnt.carry_on-ejEuO.small-3UzDt .size-2F3ev {
		bottom: 12px;
	}

	.value-1r3pi {
		font-size: 22px;
		font-weight: 700;
	}

	.value-1r3pi,
	div.size-2F3ev {
		color: var(--white);
	}

	.wrapper-Egrkj {
		color: var(--brand-1-0);
	}

	.root-1hWnt.carry_on-ejEuO.small-3UzDt .size-2F3ev,
	.root-1hWnt.carry_on-ejEuO.large-11zKp .size-2F3ev {
		bottom: 13px;
	}
}
