

.passenger-2sXyk {
	display: none;

	border-radius: 0;
}

.number-ykhLo {
	border-radius: 0;

	background: var(--brand-1-0);
}

.passenger-2sXyk.filled-3nOzR .number-ykhLo {
	background: var(--brand-1-0);
}
