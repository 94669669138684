/* stylelint-disable */

.fareGroup-3bl-l {
	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

button.Button-3mbbw {
	border-radius: 0;

	font-size: 16px;

	background-image: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);

	margin-top: auto;
}

button.Button-3mbbw:hover {
	background-color: var(--brand-3-0);
}

button.Button-3mbbw:active {
	background-color: var(--brand-3--1);
}

.fareGroup_white-OTgji.fareGroup_Business-1RIba button.Button-3mbbw {
	background-color: var(--brand-3-0);
}

button.Button-3mbbw:hover {
	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

div.recommended-wJrzp {
	border: 1px solid var(--brand-3-1);

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

svg.badge-uqczv {
	color: var(--brand-3-0);
}

.header-1A_nM {
	color: var(--text-base);
	font-weight: 700;
}

.option__title-2FVfm {
	padding-right: 4px;

	white-space: normal;
}

.fareGroup-3bl-l .option__title-2FVfm {
	font-size: 14px;
	color: var(--text-light);
	line-height: 1.2;
}

.fareGroup-3bl-l .option_free-xRGM0 .option__title-2FVfm {
	font-size: 14px;
	color: var(--brand-1-0);
}

.option-3R9Pv {
	background-color: var(--brand-1-0);
}

.fareGroup-3bl-l .paid-84kT6 {
	border: 1px solid var(--line-separator);

	opacity: 1;
	background-color: transparent;
}

.paid-84kT6 svg * {
	fill: var(--text-pale);
}

.fareGroup_white-OTgji .keyFeature__icon-1ocHa svg {
	width: var(--fareGroup-option-svg-size);
	height: var(--fareGroup-option-svg-size);

	color: var(--fareGroup-option-svg-color);
}

.fareGroup-3bl-l .option__value-2Eugw {
	width: 20px;
	min-width: 20px;
	height: 20px;

	color: var(--text-light);
}

.not_available-8lXsa {
	border: 1px solid var(--line-separator);

	opacity: 1;
	background: transparent;
}

.not_available-8lXsa .icon-1LPnw svg,
.not_available-8lXsa .availabilityIcon-1hvE6 svg {
	color: var(--brand-1-0);
}

.fareGroup-3bl-l .option__value-2Eugw svg {
	width: 100%;
	height: 100%;
}

.fareGroup_white-OTgji .option_unavailable-31Kvx .option__title-2FVfm,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__value-2Eugw {
	color: var(--text-pale);
}

.option_free-xRGM0 svg * {
	color: var(--brand-1-0);
}

.fareGroup_white-OTgji .keyFeature-2oVPM {
	background-color: var(--brand-1-0);
}

.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature-2oVPM {
	background-color: var(--brand-1-0);
}

.upgradeOffer-bPWKM {
	display: none;
}

.price-2gjU7 {
	padding-right: 0;

	font-weight: 500;
	font-size: 24px;
	line-height: 1.3;
}

.price-2gjU7:after {
	display: none;
}

.size-V14m5 {
	line-height: 1.2;
}

.icon-1LPnw {
	color: var(--text-pale);
}

.options-1dCj5 {
	padding-bottom: 40px;
}

.pagination__item-37T67 {
	background: var(--line-separator);
}

.pagination__item-37T67.pagination__item_active-3ULe6 {
	background: var(--text-light);
}

.buttons-3C9MF button.fareGroup__button-1Sc7F {
	border-radius: 0;
}

.small-273Iq .availabilityIcon-1hvE6 svg {
	width: 15px;
	height: 15px;
}

.small-273Iq .availabilityIcon-1hvE6 {
	display: block !important;

	color: var(--white);
}

.fareGroup-3bl-l .paid-84kT6 .icon-1LPnw svg,
.fareGroup-3bl-l .paid-84kT6 .availabilityIcon-1hvE6 svg {
	color: var(--brand-1-0);
}

.option-3R9Pv.small-273Iq {
	background: var(--brand-1-0);
}

.option-3R9Pv.small-273Iq.not_available-8lXsa {
	background: var(--gray);
	opacity: 1;
}

.not_available-8lXsa .title-2eoTV,
.not_available-8lXsa .size-V14m5 {
	display: none;
}

.not_available-8lXsa .availabilityIcon-1hvE6 {
	display: block !important;
}

.option-3R9Pv.small-273Iq .icon-1LPnw,
.option-3R9Pv.small-273Iq .icon-1LPnw svg {
	width: 32px;
	height: 32px;
}

.option-3R9Pv.small-273Iq.paid-84kT6 {
	background: var(--gray);
}

@media screen and (max-width: 600px) {
	.fareGroup-3bl-l .option__title-2FVfm,
	.fareGroup-3bl-l .option_unavailable-31Kvx .option__title-2FVfm {
		color: var(--text-light);
	}

	.icon_container-1XAtx,
	.fareGroup-3bl-l .option__value-2Eugw,
	.fareGroup-3bl-l .option_unavailable-31Kvx .option__value-2Eugw {
		color: var(--text-pale);
	}

	button.Button-3mbbw {
		font-size: 16px;
	}

	.fareGroup__price-36a9l {
		font-size: 24px;
	}

	.buttons-3C9MF button.fareGroup__button-1Sc7F {
		max-width: 155px;

		color: var(--text-mid);
	}

	.buttons-3C9MF button.fareGroup__button_active-3lwmU,
	.buttons-3C9MF button.fareGroup__button_business-1Ys2c.fareGroup__button_active-3lwmU {
		border-color: transparent;

		color: var(--white);

		background: var(--brand-1-0);
	}
}
