

.step-2KixS {
	border-top: 1px dashed var(--brand-1-2);
}

.name__index-12RNW {
	width: 28px;
	height: 28px;

	border: none;

	font-weight: 700;
	font-size: 14px;
	line-height: 1.2;

	background: var(--brand-1--1);
}

.name__label-UAIOK {
	display: flex;
	align-items: center;
}

.name__label-UAIOK,
.step__price-2_OJz {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.name__label-UAIOK svg {
	width: 28px;
	max-width: 28px;
	height: 28px;
	margin-right: 12px;
}

.step_disabled-39RVo .name__index-12RNW {
	color: var(--white);

	background: var(--brand-1--1);
}
