.price-3Pg_J {
	color: var(--text-base);
	font-size: 16px;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.price-3Pg_J {
		font-size: 16px;
	}
}
