

button.button-1GJEx:not(:disabled) {
	color: var(--text-light);

	background: #ffffff;
}

button.button-1GJEx:not(:disabled):hover,
button.button-1GJEx:not(:disabled):focus {
	background: #ffffff;
}
