.title-q8TZk {
	display: inline-flex;
	align-items: center;
	min-width: 164px;
	height: 40px;
	padding: 11px 14px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
	background-color: var(--bg-white);
}

.title-q8TZk:after {
	content: none;
}

.currentSorting-2_Is1 {
	position: relative;

	padding-right: 15px;
	margin-left: 5px;
}

.currentSorting-2_Is1:after {
	position: absolute;
	top: 53%;
	right: 0;

	width: 10px;
	height: 5px;

	transform: translate(5px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) right;

	transition: transform 0.15s;
	content: '';
}

.sortBy-3ci6B {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.title-q8TZk {
		width: 50%;
		max-width: none;
		padding-left: 32px;

		justify-content: center;
		height: 48px;

		background: none;
	}
}
