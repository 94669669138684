.root-FRzC7 {
	color: var(--text-mid);
}

.icon-TiCpB {
	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.root-FRzC7.review-L6eZ3:first-child {
		padding-top: 12px;
	}
}
