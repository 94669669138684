

.logo-1aHx0 {
	width: 87px;
	height: 20px;
}

.logo-1aHx0 img {
	object-fit: contain;
}

.duration-3fN94 {
	color: var(--text-light);
}

.aircraft__name__link-2NH2d {
	color: var(--brand-1-0);
}
