@media screen and (max-width: 600px) {
	.container-2CXUU {
		color: var(--text-mid);
		font-size: 16px;
		font-weight: 400;
	}

	.header-2-B0f {
		font-weight: 700;
	}
}
