.container-2Zezf {
	padding-bottom: 100px;
}

.gdsTotalPrice-3n4MJ {
	border-color: var(--line-separator);

	color: var(--text-base);
	font-weight: 400;
}

.form-3rgOv form {
	display: flex;
	flex-direction: column;
}

.messages-2u0kr {
	margin-bottom: 0;
	padding: 0;
}

@media screen and (max-width: 600px) {
	.summary__wrp-2BF2i,
	.summary-8a7hs {
		margin-top: 0;
	}

	.selectedFlight__container-1hCYD {
		margin: 12px auto;
		padding: 0 12px;
	}

	.selectedFlight__header-1D_er {
		color: var(--text-base);
		font-size: 20px;
		font-weight: 700;
		line-height: 1.3;
	}

	.checkoutServices-2mVaU {
		grid-gap: 8px;
		gap: 8px;
		padding: 0 12px 34px 12px;
	}

	.container-2Zezf {
		padding-bottom: 45px;
	}
}
