button.segment-3poTD {
	border-radius: 0;

	color: var(--text-base);
}

.segments-3E45E {
	align-items: flex-end;
	justify-content: flex-end;
	flex: initial;
	margin-left: auto;
}

.wrapper-35XkP {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.title-3n-h1 {
	left: 0;

	color: var(--text-base);
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}

.inner-37mCL {
	grid-gap: 20px;
	gap: 20px;
}

.wrapper_center-VmHm3 .segments-3E45E {
	padding-right: 0;
}
@media (max-width: 1024px) {
	.title-3n-h1 {
		display: block;
	}
}
