.classInfo-3_sm5 {
	border-radius: 0;
}

.classInfo__payload-3YX2t {
	color: var(--text-base);
	font-size: 18px;
	line-height: 1.3;
}

.title-vQX0q {
	padding-left: 40px;

	color: var(--text-base);
}

.seat_business-3m_UT .title-vQX0q:before,
.seat_preferred-2q4W3 .title-vQX0q:before,
.seat_economy-1PSrZ .title-vQX0q:before {
	width: 24px;
	height: 24px;

	border-radius: 0;
}

.seat_business-3m_UT .title-vQX0q:after {
	content: '';

	position: absolute;
	top: 3px;
	left: 6px;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);

	pointer-events: none;
}

.description-32Fqa {
	color: var(--text-mid);
}

.seat__info-DguG4 {
	border-color: var(--line-separator);
}

.feature-28c-l {
	color: var(--text-light);
	font-size: 14px;
	line-height: 1.3;
}

.price__wrapper-2-gFi {
	color: var(--text-base);
	font-weight: 700;
	line-height: 1.3;
}

.price-1b0M- {
	font-size: 14px;
}

.seat-2zxAD {
	border-radius: 0;
}

.image-1twVk {
	display: none;
}

.amenities__item-3ZYJ8 {
	width: 20px;
	height: 20px;

	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.title-vQX0q {
		min-height: 40px;
		padding-left: 56px;
	}

	.title-vQX0q span {
		padding-top: 3px;

		color: var(--white);
		font-weight: 500;
	}

	.title-vQX0q span,
	.seat_business-3m_UT .title-vQX0q:before,
	.seat_preferred-2q4W3 .title-vQX0q:before,
	.seat_economy-1PSrZ .title-vQX0q:before {
		width: 40px;
		height: 40px;
	}

	.seat_business-3m_UT .title-vQX0q:after {
		top: 3px;
		left: 14px;
	}

	button.seatService__button-tXCqL,
	button.seatService__button-tXCqL:hover {
		border-radius: 0;
		border: none;

		color: var(--white);
		font-weight: 700;
		font-size: 16px;

		background: var(--brand-3-0);
	}

	.controls-227tc {
		margin-bottom: 17px;
		padding: 0 10px;
	}

	.control-2c8ur {
		width: 40px;
		height: 40px;

		margin-right: 8px;

		border-radius: 0;

		background: var(--seat-economy-front);
	}

	.control-2c8ur:last-child {
		margin-right: 0;
	}

	.control_selected-3o4t8 {
		position: relative;

		background: var(--brand-1-0);
	}

	.control_selected-3o4t8:after {
		position: absolute;
		bottom: -10px;

		width: 100%;
		height: 4px;

		background: var(--brand-1-0);
		content: '';
	}

	.main-2E0BW {
		align-items: flex-start;

		color: var(--text-base);
	}

	.main__icon-130xx {
		color: var(--brand-3-0);
	}

	.price__wrapper-2-gFi {
		grid-gap: 4px;
		gap: 4px;
		align-items: baseline;

		font-weight: 700;
		font-size: 14px;
	}

	.price__wrapper-2-gFi,
	.money-841YH {
		color: var(--text-base);
	}

	.recommend__header-17rJo {
		padding: 20px;

		border-bottom: 1px dashed var(--line-separator);
	}

	.recommend__content-FXao6 {
		padding: 0;

		background: var(--white);
	}

	button.recommend__cancel-2qV0s {
		white-space: nowrap;
	}

	button.recommend__confirm-2-WXQ,
	button.recommend__cancel-2qV0s {
		border-radius: 0;
	}

	button.recommend__cancel-2qV0s,
	button.recommend__cancel-2qV0s:hover {
		color: var(--text-light);

		background: var(--bg-fill);
	}

	button.recommend__confirm-2-WXQ,
	button.recommend__confirm-2-WXQ:hover {
		background: var(--brand-3-0);
	}

	div.serviceInfo__slider-29rQ- {
		background: var(--white);
	}

	div.serviceInfo__slider-29rQ- div {
		box-shadow: none;
	}

	.seat-2zxAD {
		max-width: 100%;
	}

	.slide-1xs2A .seat-2zxAD {
		padding: 0 10px;
	}

	.price-1b0M- {
		padding-left: 0;
	}
}
