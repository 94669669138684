.loginWrapper-2spdw {
	border-radius: 0;

	box-shadow: none;
}

.active__route-2qUuS {
	top: 0;
}

h1.route__title-2vs2x {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	h1.route__title-2vs2x {
		font-size: 20px;
	}
}
