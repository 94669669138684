

button.segment-34rr- {
	border-radius: 0;

	color: var(--text-base);

	background: var(--bg-elements);
}

.checked-tAeFI circle {
	fill: var(--brand-3-0);
}
