

.disclaimer-266eU {
	position: relative;

	padding: 12px 10px 12px 38px;

	background: var(--brand-2-5);
}

.disclaimer-266eU > span {
	position: absolute;
	top: 14px;
	left: 10px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjY2NjY4NyA4Ljk5OTg0QzAuNjY2Njg3IDQuMzk5ODQgNC40MDAwMiAwLjY2NjUwNCA5LjAwMDAyIDAuNjY2NTA0QzEzLjYgMC42NjY1MDQgMTcuMzMzNCA0LjM5OTg0IDE3LjMzMzQgOC45OTk4NEMxNy4zMzM0IDEzLjU5OTggMTMuNiAxNy4zMzMyIDkuMDAwMDIgMTcuMzMzMkM0LjQwMDAyIDE3LjMzMzIgMC42NjY2ODcgMTMuNTk5OCAwLjY2NjY4NyA4Ljk5OTg0Wk05LjgzMzM1IDguMTY2NVYxMy4xNjY1SDguMTY2NjlWOC4xNjY1SDkuODMzMzVaTTkuMDAwMDEgMTUuNjY2NUM1LjMyNTAxIDE1LjY2NjUgMi4zMzMzNSAxMi42NzQ4IDIuMzMzMzUgOC45OTk4NEMyLjMzMzM1IDUuMzI0ODQgNS4zMjUwMSAyLjMzMzE3IDkuMDAwMDEgMi4zMzMxN0MxMi42NzUgMi4zMzMxNyAxNS42NjY3IDUuMzI0ODQgMTUuNjY2NyA4Ljk5OTg0QzE1LjY2NjcgMTIuNjc0OCAxMi42NzUgMTUuNjY2NSA5LjAwMDAxIDE1LjY2NjVaTTkuODMzMzUgNC44MzMxN1Y2LjQ5OTgzSDguMTY2NjlWNC44MzMxN0g5LjgzMzM1WiIgZmlsbD0iIzc1NzU4QyIvPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
}

.disclaimer-266eU a {
	color: var(--text-mid);
	line-height: 1.2;
}

.disclaimer-266eU p {
	white-space: pre-wrap;
}
