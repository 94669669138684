.icon-3OluO {
	display: none;
}

button.button-2s2Bf {
	border-radius: 0;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

button.no-x2Q-A,
button.no-x2Q-A:hover {
	background: var(--bg-fill);
}

@media screen and (max-width: 600px) {
	.content-39ClI {
		font-size: 16px;
		color: var(--text-light);
	}

	button.button-2s2Bf {
		border-radius: 0;
	}

	.content-39ClI > div {
		width: 100%;
	}
}
