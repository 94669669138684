.closeIcon-te75K {
	top: 15px;
	right: 15px;
	z-index: 11;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;

	border-radius: 50%;

	color: var(--text-base);

	background: var(--bg-fill);
}

.closeIcon-te75K svg {
	width: 60%;
	height: 60%;
}

div.modal-1-H6U {
	border-radius: 0;
}

div.modal-1-H6U > button {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.closeIcon-te75K {
		top: 12px;
		right: 12px;

		z-index: 20;

		color: var(--text-light);
	}
}

@media screen and (max-width: 600px) {
	.closeIcon-te75K {
		color: var(--text-light);
	}
}
