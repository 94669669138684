.title-3OrVB {
	color: var(--text-base);
	font-size: 22px;
	line-height: auto;
	font-weight: 700;
}

.title__icon-3TDvp {
	display: none;
}

.form__row-1HLGs {
	margin-top: 25px;
}


@media screen and (max-width: 600px) {
	.title-3OrVB {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 18px;
	}

	.form__row-1HLGs:last-of-type {
		margin-top: 42px;
	}
}
