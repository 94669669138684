

.stepbar-30GMX {
	border-bottom: 1px solid var(--line-separator);
}

.content-oVooA {
	grid-gap: 30px;
	gap: 30px;
}

.step-2WkVh {
	flex: unset;

	border: none;

	color: var(--text-light);
	font-size: 14px;
	text-transform: none;
}

.id-5rWAa {
	display: none;
}

.step-2WkVh.active-2oY8h {
	border-bottom: 3px solid var(--brand-1-0);
}

.step-2WkVh.passed-2ZLxT {
	color: var(--text-light);
}

.step-2WkVh.disabled-2q3DA {
	color: var(--text-pale);
}

.cart-1n1ua * {
	color: var(--brand-1-0);
}
