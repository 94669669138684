

div.container-2YDpy {
	border-radius: 0;

	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.passengerType-1ZFYo {
	font-size: 16px;
	font-weight: 500;
}

.age-XzPT4 {
	font-size: 13px;
	font-weight: 400;
}

.info__icon-2m-Ao {
	display: none;
}

button.increase-Qk_Yc,
button.decrease-3Ds89 {
	width: 26px;
	height: 26px;
}

.passengers-4D9Mx {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.item-2XZRs:not(:last-of-type) {
	padding: 0;

	border: none;
}

.item-2XZRs:not(:first-of-type) {
	padding: 0;
}

.container-2YDpy .notification-2b0N5 {
	padding: 16px 12px;

	border: solid var(--warning-icon) 1px;
	border-radius: 0;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 15px;

	background: var(--warning-bg);
}

.container-2YDpy .notification-2b0N5 .icon-xmY8k {
	display: none;
}

.container-2YDpy .text-1xbuk {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-mid);
}

.buttons-28Fsh button,
.buttons-28Fsh button:hover {
	border-radius: 0;

	font-size: 16px;
}

button.newSearch-CkLmu:hover,
button.newSearch-CkLmu:focus {
	background: var(--brand-1-4);
}

button.continueButton-3vREp {
	background: var(--bg-accent);
}

button.continueButton-3vREp:hover,
button.continueButton-3vREp:focus {
	background: var(--bg-accent-hover);
}
