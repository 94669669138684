

.buttons-1WQLx button {
	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

.buttons-1WQLx button:hover {
	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}
