

.title-2-foq {
	display: flex;
	align-items: center;
}

.number-1ZlLf {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	min-width: 36px;
	height: 36px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.2;

	background: var(--brand-3-0);
}

.text-2fWHN {
	color: var(--brand-3-0);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.cities-2TU_0 {
	color: var(--brand-1-0);
}
