@media screen and (max-width: 600px) {
	.container-22ezQ {
		padding: 0;

		border-radius: 0;
	}

	.header-k04rF {
		padding: 12px;

		color: var(--text-base);
		font-size: 18px;

		background: var(--white);
	}

	.header-k04rF button {
		padding: 5px;

		border-radius: 50%;

		color: var(--text-light);

		background: var(--bg-fill);
	}

	.header-k04rF button svg {
		transform: scale(0.8);
	}

	.container-22ezQ > div:not(.header-k04rF):not(.recommend__content-zMoCN) {
		padding: 12px;
	}
}
