

button.button-1FaZC {
	border-radius: 0%;

	color: var(--bg-white);

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

button.button-1FaZC:hover,
button.button-1FaZC:focus {
	color: var(--bg-white);

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

button.button-1FaZC:disabled {
	color: var(--gray30);

	background: var(--gray10);

	cursor: not-allowed;
}
