.searchForm-QESAH {
	background: var(--bg-fill);
}

div.segment__wrapper-1w8ia {
	border-right: 0;
}

div.location_autocomplete-3gval {
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

input.location_departure-2sXMf {
	padding: 0 16px;
}

div.location_autocomplete-3gval input.input-33j6e.input_focused-2fE-L:-ms-input-placeholder {
	color: var(--text-light);

	opacity: 1;
}

div.location_autocomplete-3gval input.input-33j6e.input_focused-2fE-L::placeholder {
	color: var(--text-light);

	opacity: 1;
}

div.cell_location-21E1n {
	border-right: 0;
}

.searchForm-QESAH div.cell_location-21E1n {
	flex-basis: 140px;
	flex-grow: 0;
}

div.cell_location-21E1n:first-child {
	margin-right: 15px;
}

div.cell_location-21E1n:not(:last-child):not(:first-child) {
	margin-right: 30px;
	margin-left: 15px;
}

button.switcher-2o4os {
	right: -30px;

	opacity: 1;
}

button.switcher-2o4os svg path {
	fill: var(--brand-3-0);
}

div.dates-2MeLb {
	margin: auto;

	background: none;
}

div.dates-2MeLb:not(.dates_focus-2MPaM):hover {
	background: none;
}

div.dates_to-c9zrQ,
div.dates_back-2LtxF {
	padding-left: 0;

	border-bottom: 1px solid #a3a3b3;

	color: var(--text-base);
}

div.dates_to-c9zrQ {
	margin-right: 30px;
}

div.dates_to-c9zrQ:hover,
div.dates_back-2LtxF:hover,
div.dates_to_focused-2iJYh,
div.dates_back_focused-3W-73 {
	border-bottom: 1px solid #8a1776;
}

div.dates_to-c9zrQ:before,
div.dates_back-2LtxF:before,
div.dates_focus-2MPaM div.dates_to-c9zrQ:before {
	content: '';

	position: absolute;
	right: 0;
	left: auto;

	width: 24px;
	height: 24px;

	opacity: 1;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCA0SDE5QzIwLjEgNCAyMSA0LjkgMjEgNlYyMEMyMSAyMS4xIDIwLjEgMjIgMTkgMjJINUMzLjg5IDIyIDMgMjEuMSAzIDIwTDMuMDEgNkMzLjAxIDQuOSAzLjg5IDQgNSA0SDZWMkg4VjRIMTZWMkgxOFY0Wk01IDIwSDE5VjlINVYyMFoiIGZpbGw9IiNDNTYzOEYiLz4KPC9zdmc+Cg==) center no-repeat;
}

div.dates_back-2LtxF:before {
	border-left: none;
}

div.dates_focus-2MPaM div.dates_back-2LtxF:before {
	display: none;
}

div.dates_focus-2MPaM div.dates_to-c9zrQ:after {
	display: none;
}

div.dates_focus-2MPaM div.returnDelete-c-gl5 {
	right: 0;

	color: var(--brand-3-2);
}

span.dates_placeholder-7sVce {
	color: var(--text-base);
}

div.passengers-2fsUu {
	margin-left: 30px;
}

button.passenger_selector-3uiUo,
button.passenger_selector_open-1YC_1 {
	padding: 0;

	border-bottom: 1px solid #a3a3b3;

	color: var(--text-base);

	background: none;
}

div.passengers-2fsUu button.passenger_selector-3uiUo:not(:focus):hover,
button.passenger_selector_open-1YC_1 {
	border-bottom: 1px solid #8a1776;

	background: none;
}

span.passengers_arrowIcon-10k00 {
	color: var(--brand-3-2);
}

div.startSearch-7XfGB {
	flex: 50px 0;
	margin-left: 30px;
}

div.startSearch-7XfGB button {
	width: 50px;
	min-width: 50px;
	height: 50px;
	padding: 0;

	border-radius: 0 !important;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

.startSearch-7XfGB.iconMode-3U1nI button {
	min-width: unset;
}

div.startSearch-7XfGB button:hover {
	width: 50px;
	min-width: 50px;
	height: 50px;
	padding: 0;

	border-radius: 0 !important;

	background: linear-gradient(225deg, #c5638f 0%, #8a1776 100%);
}

div.controls-2hn_S {
	align-items: center;
}

button.passenger_increase-3S8IG,
button.passenger_decrease-3XV7E {
	color: var(--brand-1-0);

	background: var(--brand-1-5);

	fill: var(--brand-1-0);
}

button.passenger_increase-3S8IG svg,
button.passenger_decrease-3XV7E svg {
	fill: var(--brand-1-0);
}

button.passenger_increase-3S8IG:hover,
button.passenger_decrease-3XV7E:hover {
	background: var(--brand-1-0);
}

button.passenger_increase-3S8IG:hover svg,
button.passenger_decrease-3XV7E:hover svg {
	color: var(--white);

	fill: var(--white);
}

button.passenger_increase-3S8IG:disabled,
button.passenger_decrease-3XV7E:disabled {
	background: var(--bg-elements);
}

button.passenger_increase-3S8IG:disabled svg,
button.passenger_decrease-3XV7E:disabled svg {
	color: var(--line-dot-line);
}

.counter-2Zmaf {
	color: var(--brand-2-0);
}

@media (max-width: 1024px) {
	div.segment__wrapper-1w8ia {
		align-items: center;
	}

	div.cell_dates-2Ohjz {
		flex-basis: 48%;
		margin-left: auto;
	}

	div.dates_to-c9zrQ {
		margin-right: 0;
	}

	div.controls-2hn_S {
		position: absolute;
		top: 142px;
		left: 0;

		flex-basis: 100%;

		width: 48%;
	}

	div.bottom-1VAmP {
		justify-content: flex-end;
		grid-gap: 4px;
		gap: 4px;

		width: 48%;
		min-height: 48px;

		margin-top: 40px;
		margin-right: 0;
	}

	div.bottom-1VAmP > div:last-child {
		margin-right: -20px;
	}

	div.passengers-2fsUu {
		margin-left: 20px;
	}

	div.cell_location-21E1n:first-child {
		padding: 0;
	}

	div.cell_location-21E1n:not(:last-child):not(:first-child) {
		margin-right: 0;
	}
}

@media screen and (max-width: 600px) {
	div.cell_dates-2Ohjz {
		flex-basis: 100%;
	}

	div.segments-3RUNc {
		display: flex;
		justify-content: center;
	}

	div.segments-3RUNc > div {
		width: 100%;
	}

	div.segment__wrapper-1w8ia {
		justify-content: center;
		margin-top: 20px;
	}

	div.bottom-1VAmP {
		justify-content: unset;

		width: 100%;
	}

	div.bottom-1VAmP > div:not(:last-of-type) {
		border-bottom: 0;
	}

	div.bottom-1VAmP > div:last-child {
		margin-right: 0;
	}

	div.cell_dates-2Ohjz,
	div.controls-2hn_S {
		margin-top: 40px;
	}

	.mobileTitle-qZcEf {
		font-family: 'Roboto', sans-serif;
		color: var(--brand-2-0);
	}

	.passengerType-2YmIr {
		color: var(--brand-2-0);
	}

	.age-3fOXo {
		color: var(--text-light);
	}

	.searchForm-QESAH {
		height: 100%;
	}

	div.cell_location-21E1n:first-child,
	div.cell_location-21E1n:not(:last-child):not(:first-child) {
		flex-basis: 50%;
		margin-right: 0;
		margin-left: 0;
		flex-grow: 1;
	}

	div.segment-2EqSp {
		width: 100%;
	}

	.alternative_dow-2z8qW {
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: var(--text-base);

		opacity: 1;
	}

	div.segment-2EqSp button.switcher-2o4os {
		background: none;
	}

	div.segment-2EqSp div.dates_to-c9zrQ {
		margin-right: 0;
		align-items: flex-start;

		color: var(--text-base);
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
	}

	div.dates-2MeLb:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		width: 24px;
		height: 24px;
		margin: auto;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCA0SDE5QzIwLjEgNCAyMSA0LjkgMjEgNlYyMEMyMSAyMS4xIDIwLjEgMjIgMTkgMjJINUMzLjg5IDIyIDMgMjEuMSAzIDIwTDMuMDEgNkMzLjAxIDQuOSAzLjg5IDQgNSA0SDZWMkg4VjRIMTZWMkgxOFY0Wk01IDIwSDE5VjlINVYyMFoiIGZpbGw9IiNDNTYzOEYiLz4KPC9zdmc+Cg==) center no-repeat;
		content: '';
	}

	div.passengers-2fsUu {
		max-width: 100%;
		margin-left: 0;
	}

	span.passengers_arrowIcon-10k00 {
		display: flex;
	}

	div.cell_dates-2Ohjz,
	div.controls-2hn_S,
	div.startSearch-7XfGB {
		margin-top: 30px;
	}

	div.controls-2hn_S {
		position: relative;
		top: 0;

		display: block;
		flex-basis: 100%;
		justify-content: center;

		width: 100%;
	}

	div.startSearch-7XfGB {
		position: relative;
		top: 0;
		left: 0;

		width: 100%;
		margin-left: 0;
	}

	div.startSearch-7XfGB button,
	div.startSearch-7XfGB button:hover {
		width: 100%;
	}

	div.passengers-2fsUu span.passengers_title-2VkrP {
		text-align: left;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-size: 16px;
	}
}
