

button.button-AWZPc {
	border: none;
	border-radius: 0;

	color: var(--brand-2-1);

	background: var(--bg-fill);
}

button.button-AWZPc:hover {
	color: var(--brand-2-1);

	background: var(--bg-fill);
}

button.button-AWZPc > svg {
	color: var(--brand-2-1);
}

.button__price-8vnfu {
	color: var(--brand-2-1);
}

.fare-3xvBa .option-1umWw > span {
	color: var(--text-light);
}

.fare-3xvBa .option-1umWw > span > svg {
	color: var(--text-pale);
}

div.fare-3xvBa {
	border-radius: 0;

	box-shadow: none;
}

.fareConditions__closeButton-o3E-k {
	z-index: 11;
}
