.wrapper-3Sen_ {
	position: relative;
}

.tag-1JUzy {
	position: absolute;
	top: 0;
	right: 20px;

	padding: 4px 10px;

	border-radius: 2px;

	color: #ffffff;
	font-size: 12px;
	line-height: 1.2;

	transform: translate(0, -50%);
	background: var(--brand-3-2);
}

@media screen and (max-width: 600px) {
	.tag-1JUzy {
		top: 5px;
		right: 5px;

		transform: unset;
	}
}
