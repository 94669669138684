.loginForm-1WAFc button.loginForm__button-2GS1U {
	min-width: 160px;
	height: 56px;
	min-height: 56px;
	margin-top: 20px;

	border-radius: 0;

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

.social__text-1p7Em {
	display: none;
}

.social__buttons-XrC_C {
	margin-left: 0;
}

.alternative-3JqgZ {
	display: none;
}

@media screen and (max-width: 600px) {
	.wrapper-2Uqwy {
		justify-content: inherit;
	}

	.loginForm-1WAFc button.loginForm__button-2GS1U {
		height: 50px;
		min-height: 50px;
	}

	.social-2v3SS {
		margin-top: 0;
	}

	.social__text-1p7Em {
		display: inherit;

		color: var(--text-light);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.3;
	}

	.footer-2NqQv {
		margin-top: 48px;
	}
}
