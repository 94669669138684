

.header__text-2gKqy {
	color: var(--text-mid);
	font-weight: 700;
	line-height: 1.3;
}

.content__icon-3umbe {
	color: var(--brand-1-0);
}

.content__item-2cNJA {
	border-color: var(--line-separator);
}

.segment__header-3lKqb.segment__header_flights-1ix_W {
	text-decoration: none;
}

.item__row-2RiEV,
.passenger-2vqp0,
.baggage-2-sxk {
	color: var(--text-mid);
}

.price__money-181C0 {
	color: var(--brand-1-0);
}

.baggage__weight-19O6p,
.passenger__seat-2Pr19 {
	color: var(--text-light);
}
