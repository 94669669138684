.item-Dr0Nf {
	display: flex;
	flex-direction: column;

	border-radius: 0;

	background: var(--bg-fill);
}

.root-3sPnC {
	background: var(--brand-3-0);
}

.direction__root-25JRb {
	color: var(--brand-3-0);
	font-weight: 700;
}

.counts-2e-e9 {
	margin-top: auto;
	margin-bottom: 35px;
}

@media screen and (max-width: 600px) {
	.item-Dr0Nf {
		height: 239px;
		padding: 20px 8px;

		border-radius: 4px;

		background: var(--bg-fill);
	}

	.root-3sPnC {
		font-weight: 500;
	}

	.direction__root-25JRb {
		font-weight: 500;
		font-size: 11px;
	}

	.counts-2e-e9 {
		margin-bottom: auto;
	}
}
