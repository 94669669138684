

.title-j1fRP {
	grid-gap: 6px;
	gap: 6px;

	font-weight: 400;
}

.title-j1fRP img {
	width: 104px;
	height: 24px;
}
