

label.label-1uxWc {
	color: var(--text-base);
}

label.label-1uxWc span {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.label-1uxWc.active-Xejl3,
span.radio-thIxu.active-Xejl3 {
	color: var(--brand-1-0);
}

span.radio-thIxu:hover {
	background: rgba(50, 144, 255, 0.1);
}

.stepChoosed-3pXUs h6 {
	color: var(--text-base);
}
