/* stylelint-disable */

label.textField_filled-1Ud6Y.textField_focused-pTqbt,
label.textField__root-2XueY.label_error-2opVC,
.input-26qiI.disabled-31wvA,
.real_input-3XDKD.disabled-31wvA {
	color: var(--text-light);
}

input.real_input-3XDKD {
	color: var(--text-base);
}

div.input-26qiI.disabled-31wvA:before,
div.real_input-3XDKD.disabled-31wvA:before {
	background: none;
}

div.input-26qiI.input_filled-3dBe9 .real_input-3XDKD,
div.input-26qiI.input_focused-24U_i .input_outline-28yGz,
div.input-26qiI.input_focused-24U_i.input_filled-3dBe9 .real_input-3XDKD {
	border: none;
	border-radius: 0;
}

div.input-26qiI.input_filled-3dBe9 .real_input-3XDKD {
	padding: 26px 0 9px;

	border-bottom: 1px solid var(--text-light);
}

div.input-26qiI.input_focused-24U_i .input_outline-28yGz,
div.input-26qiI.input_focused-24U_i.input_filled-3dBe9 .real_input-3XDKD {
	border-bottom: 1px solid var(--brand-3-0);
}

label.textField__root-2XueY {
	left: -10px;

	height: 18px;

	color: var(--text-light);
	transform: translate(10px, 25px) scale(0.9);
}

label.textField__root-2XueY.label_shrink-3GtTn {
	transform: translate(10px, 10px) scale(0.75);
}

label.textField__root-2XueY.label_error-2opVC {
	color: var(--text-light);
}

div.underline-3w1Vs:before {
	border-color: var(--text-light);
}

div.underline-3w1Vs:after {
	content: none;
}

div.underline-3w1Vs:hover:not(.disabled-31wvA):before {
	border-bottom: 1px solid var(--text-light);
}

div.input-26qiI.input_focused-24U_i .input_outline-28yGz,
div.input-26qiI.input_focused-24U_i.input_filled-3dBe9 .real_input-3XDKD,
div.input-26qiI.input_error-3D54h.input_filled-3dBe9 .real_input-3XDKD,
div.input-26qiI.input_focused-24U_i.input_error-3D54h.input_filled-3dBe9 .real_input-3XDKD {
	background: none;
}

label.textField__root-2XueY.textField_focused-pTqbt.textField_standard-1gnLq,
label.textField_standard-1gnLq.label_shrink-3GtTn {
	transform: translate(10px, 10px) scale(0.75);
}

p.hint-1xrf1 {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	letter-spacing: 0;
	margin: 4px 0 0 0;
}

p.hint-1xrf1.hint_error-1uzUj {
	margin-left: 0;

	color: var(--error-icon);
}

div.input-26qiI.disabled-31wvA.input_filled-3dBe9 {
	border: 0;
	border-radius: 0;

	border-bottom: 1px solid var(--text-light);
}

@media screen and (max-width: 600px) {
	label.textField_filled-1Ud6Y.textField_focused-pTqbt,
	label.textField__root-2XueY.label_error-2opVC {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
	}

	div.input-26qiI.input_focused-24U_i .input_outline-28yGz,
	div.input-26qiI.input_focused-24U_i.input_filled-3dBe9 .real_input-3XDKD,
	div.input-26qiI.input_error-3D54h.input_filled-3dBe9 .real_input-3XDKD,
	div.input-26qiI.input_focused-24U_i.input_error-3D54h.input_filled-3dBe9 .real_input-3XDKD {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-size: 16px;
	}

	p.hint-1xrf1.hint_error-1uzUj {
		white-space: pre-wrap;
	}

	div.input-26qiI.input_standard-3him0 .real_input-3XDKD {
		padding: 26px 0 9px;
	}
}
/* stylelint-enable */
