

div.modal-3nPeM {
	max-width: 900px;
}

.modal__title-303IP {
	padding-right: 80px;
}

button.button_remove-_Pafj {
	padding: 0 6px;
	width: 205px;
}

button.modal__footerBtn-3p1lc {
	min-width: 140px;

	padding: 14px 24px;
}

button.modal__footerBtn-3p1lc:last-child {
	margin-right: 0;
}

div.aeroexpress__scrollBody-1i6w4 div.aeroexpress_paper-CE6xg {
	border-radius: 0;
}
