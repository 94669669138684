

.wrapper-3mFJl,
.header-bBuXS {
	border-radius: 0;
}

.header-bBuXS {
	position: relative;

	padding-bottom: 80px;
}

.dividerIcon-1nJbY {
	position: absolute;
	left: 50%;

	width: 80px;
	height: 80px;

	transform: translateX(-50%);
}

.dividerIcon-1nJbY.dividerIcon_failed-16znB {
	border-radius: 50%;

	background: var(--error-icon);
}

.dividerIcon-1nJbY.dividerIcon_success-1YrNC {
	border-radius: 50%;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPVSURBVHgBzZkxTBNRGMf/313TYkStiQIxMdbBxEQTalxUBk/FRAcTdFA3dNINiUJlkeqitBp1dFI3dVCMiTERQx0gbJTEwbhQNDGAiRYEhQr3/N5B4Qql9I6+tr+BvL67oz/ePb7vve8R1kDYf8W/zus1BAlDA+3griAJ+AXBL69zO8nthIBICmCABMX+plKxcPJBEi4huCC6JWRAN9shKJiWc0inEHgaGo10wiGOhDuqWhuIcJ+bARSGhGYifO1H5Gm+D+QlfLsmFPAI87EAGVBDfJbodNtwR2K1G7XVbrhb3dLkMUW/QllJUBeiv6O69cpqN+q5LkaqWtsF0R1+DxVQTwW/7hPH19fh/WTPx5VuWlFYyrJoGMWGYOSSzipcMtk0OaSXCcs5a02DUsPS9ZV1Y12TPX2Z3TasaCD/wdzFVhUkOXrss0ePjCihm+arMpKV+DUOp/aOBeHI1pYLIAqizCAOpzJhpT8vjrBG7ShT5rOrhSXcUXVV/gUBlC+BaM1VQzbmR1hvRBHxbazAxu2bHT1jCs2aAXSfl4j/fN5fKBJS9vzLS/BtWodnZx5h/FveX538M53aqaW8uoEikZat2rsNm3iEj9065eRx/3qf77BGpBkoAnZZyein73jb9MLJr8AscETjzFELxWSTldNhenwKzjADGq/8lSaKwsnKCEG1PMIUgEOkRGNXE/ae27/qfYWSnYczn4tU3PCk0ZI4+fDsitIKZOeE4YLuG28wNf/F2aQVyVpocisOh0iB5yyQTVqlLJPUj1fWXeaG42kxOfobie4v2N0QhMfnwa6Te5BiqaMcWxXJSj7LKBGHS5aO9BG1spAFGU4cYghrYKl0uq/QshIBbYBH2IxhjdilVclKeD/XbS1+ZrzewULsNOR0GPv6U4mshBc/m7VmWZgj4Xoe25Gjq0rWhHgti4hWHJ4h8ybKHB2atbdb2DVzmWiQynTXwaXaRGgkslO2FzOdmG1GuWIr6mTUJaLVLd2Ki35uiLeORPalP2SsJWZIu+gmVStDWIWU0/aujFLVh4meZH3lwWlecp5AGcChtu36SOSdvW9Zba1rsrevfsMhohJPDT43uRkaiS6r8WWtXnZN9MZKKW3JDkfD2a6tWB8ulXQuWUnOCrwlXXlwjLclB5RX4eeOyNqyTQM7eR/KyMqmqmIhLxtjJkeofA5lHB17RWpaLghB7YXKiDKD8Y9mJ+d1rg4WZfGQyKrHNTh+2Hr1Im7y+qVt+F4MDnElnGbu6Fbno1uPocOsnatxUADppapMQmQlorgJGiIxw0e3s2s6uv0PVybKd5nXiyYAAAAASUVORK5CYII=) center center / contain no-repeat;
}

.dividerIcon-1nJbY.dividerIcon_failed-16znB:before,
.dividerIcon-1nJbY.dividerIcon_failed-16znB:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 4px;

	background: var(--white);
	transform: translate(-50%, -50%) rotate(-45deg);
}

.dividerIcon-1nJbY.dividerIcon_failed-16znB:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

button.button_failed-pUkX8 {
	margin-right: 12px;
}
