

.container_dropdown-vZNsT {
	border-radius: 0;
}

div.segment__card-1d9p1.segment__card-1d9p1 {
	box-shadow: none;
}

.time-3Dnez {
	border-radius: 0;
}
