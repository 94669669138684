

.header-1RyeM > svg {
	display: none;
}

.number-YPyBc {
	border-radius: 0;
}

.passengerName-2kgC7 {
	font-weight: 700;
	line-height: 1.3;
}

.header-1RyeM.filled-1Fs03 .number-YPyBc {
	background: var(--brand-1-1);
}
