

.insuranceUpsale__container-5R03t {
	background: var(--additional-insurance);
}

.insuranceUpsale__content-dH8gt a,
.insuranceUpsale__options-1ZXZr a {
	color: var(--text-link);
}

.insuranceUpsale__options-1ZXZr li:before {
	background: var(--white);
}
