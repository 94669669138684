

.container-1CEwA {
	border-radius: 0%;

	background-color: var(--brand-3-0);
}

.suggestions-1glJB {
	order: 2;
}

.container-1CEwA > svg {
	display: none;
}

.text-y0uHw {
	text-decoration: none;
}
