.insurance-3w3Pl {
	border: 1px solid var(--line-separator);
	border-radius: 4px;
	overflow: hidden;

	transition: border-color 0.15s;
}

.insurance-3w3Pl.selected-5qFV5 {
	border-color: var(--brand-1-2);
}

.insurance__header-1d9Xv {
	min-height: 105px;

	border-bottom: none;

	color: var(--white);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;

	background: var(--brand-1-0);
}

.selected-5qFV5 .insurance__header-1d9Xv:after {
	width: 22px;
	min-width: 22px;
	height: 22px;
	margin-left: 6px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC4yNSA5LjVDMTguMjUgMTQuMzMyNSAxNC4zMzI1IDE4LjI1IDkuNSAxOC4yNUM0LjY2NzUxIDE4LjI1IDAuNzUgMTQuMzMyNSAwLjc1IDkuNUMwLjc1IDQuNjY3NTEgNC42Njc1MSAwLjc1IDkuNSAwLjc1QzE0LjMzMjUgMC43NSAxOC4yNSA0LjY2NzUxIDE4LjI1IDkuNVpNMTQuNDkzNyA3LjQ5MzcyQzE0LjgzNTQgNy4xNTIwMSAxNC44MzU0IDYuNTk3OTkgMTQuNDkzNyA2LjI1NjI4QzE0LjE1MiA1LjkxNDU3IDEzLjU5OCA1LjkxNDU3IDEzLjI1NjMgNi4yNTYyOEw4LjM0ODY4IDExLjE2MzlMNS43NDM3MiA4LjU1ODkxQzUuNDAyMDEgOC4yMTcyIDQuODQ3OTkgOC4yMTcyIDQuNTA2MjggOC41NTg5MUM0LjE2NDU3IDguOTAwNjIgNC4xNjQ1NyA5LjQ1NDY0IDQuNTA2MjggOS43OTYzNUw4LjM0ODY4IDEzLjYzODhMMTQuNDkzNyA3LjQ5MzcyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
	content: '';
}

.insurance__money-1_LTb {
	color: var(--white);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.option-3VKxG {
	margin-bottom: 16px;
	padding-bottom: 16px;

	border-bottom: 1px dashed var(--line-separator);

	color: var(--text-mid);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.option-3VKxG:last-child {
	margin-bottom: 0;
}

.option-3VKxG:after {
	color: var(--text-pale);
}

button.insurance__button-3l8fq {
	margin-top: 16px;

	border: none;
	border-radius: 0;

	color: var(--white);

	background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
}

button.insurance__button-3l8fq.insurance__button_selected-1BOBj {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.insurance__button-3l8fq.insurance__button_selected-1BOBj:after {
	display: none;
}

@media screen and (max-width: 600px) {
	button.insurance__button-3l8fq {
		padding-right: 12px;
	}

	button.insurance__button-3l8fq:hover {
		color: var(--white);

		background: linear-gradient(225deg, var(--brand-3-2) 0%, var(--brand-3-0) 100%);
	}

	button.insurance__button-3l8fq:after {
		display: none;
	}

	button.insurance__button-3l8fq.insurance__button_selected-1BOBj:hover {
		color: var(--text-light);

		background: var(--bg-fill);
	}

	button.insurance__button-3l8fq.insurance__button_selected-1BOBj .insurance__money-1_LTb {
		color: var(--text-light);
	}
}
