.paper_inline-1aFQT {
	display: flex;
	flex-direction: column-reverse;
}

.paper_inline-1aFQT .buttons-2UMID {
	align-items: flex-end;
	margin: 0;
}

.paper_inline-1aFQT .buttons-2UMID a {
	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 16px;
}

.paper_inline-1aFQT .buttons-2UMID a svg {
	display: none;
}

.paper_inline-1aFQT .content_wrapper-JIGyb {
	padding: 0;
}

.isMobile-1zZh2 .location-3Toh9:first-child {
	border-radius: 0%;
	border-bottom: 1px solid #d9d9d9;
}

.isMobile-1zZh2 .location-3Toh9:last-child {
	border-radius: 0%;
	border-bottom: 1px solid #d9d9d9;
}

.locations-12Tb8 {
	flex: initial;
}

.slider-geeU2 .slick-track {
	display: flex;
}

.slider-geeU2 .slick-track > div {
	height: auto;
}

.slider-geeU2 .slick-track > div > div {
	height: 100%;
}

.slider-geeU2 .slick-track > div > div > div {
	min-height: auto;
	height: 100%;
}

.flight-2KUdD {
	flex: 1;
}

.pagination__index-3Z9Ne {
	display: none;
}

.slider__wrp-3UxDV {
	margin-bottom: 0;
}

.transfer-1f0yY {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.slider__wrp-3UxDV .slick-track {
		display: flex;
	}

	.slider__wrp-3UxDV .slick-track .slick-slide > div {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.slider__wrp-3UxDV .slick-track .slick-slide > div > div {
		width: 100%;
	}

	.slider__wrp-3UxDV .slick-track .slick-slide:not(:last-of-type) {
		margin-right: 10px;
	}
}
