.baggage-W9GD7 {
	background: var(--bg-fill);
}

@media screen and (max-width: 600px) {
	.baggage-W9GD7 {
		height: auto;

		background: var(--bg-fill);
	}

	.items-dzUrL {
		margin-bottom: 13px;
	}
}
